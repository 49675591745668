import axios, { AxiosInstance } from 'axios'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

const ENVIRIONMENT =
  getCurrentEnvironment() === ToastEnvironment.PREPROD ? 'preprod' : 'prod'

const environmentURLs = {
  preprod: '//ws-preprod-api.eng.toasttab.com',
  prod: '//ws-api.toasttab.com'
}

const loyaltyURL = '/loyalty/v1'

const axiosInstance = axios.create({
  baseURL: environmentURLs[ENVIRIONMENT] + loyaltyURL
})

export const initialiseApiIntance = (): AxiosInstance => {
  return axiosInstance
}
