import * as React from 'react'
import { EmptyPlateIcon } from '@local/assets'
import { t } from '@local/translations'

export interface HistoryTabEmptyStateProps {
  testId?: string | number
}

/**
 * HistoryTabEmptyState component
 */
export const HistoryTabEmptyState = ({
  testId = 'history-tab-empty-state'
}: HistoryTabEmptyStateProps) => {
  return (
    <div
      data-testid={testId}
      className='flex justify-center flex-col items-center'
    >
      <EmptyPlateIcon testId={testId + '-plate-icon'} className='w-72' />
      <p
        className='text-center type-default font-semibold'
        data-testId={testId + '-title'}
      >
        {t('loyalty-page-new-version.history-tab-empty-state.title')}
      </p>
      <p
        className='text-center type-default'
        data-testId={testId + '-description'}
      >
        {t('loyalty-page-new-version.history-tab-empty-state.description')}
      </p>
    </div>
  )
}
