import { AxiosInstance, AxiosResponse } from 'axios'

export interface ICCardData {
  secureToken: string
  linkToken: string
  restaurantGuid: string
}

export const ccLinkRepository = async (
  card: ICCardData,
  instance: AxiosInstance
): Promise<AxiosResponse> => {
  return instance.delete(`/guest/account/cc-link`, { data: card })
}
