import * as React from 'react'

export interface CardApplePayIconProps {
  testId?: string | number
  className?: string
}

export const CardApplePayIcon = ({
  testId,
  className
}: CardApplePayIconProps) => {
  return (
    <i data-testid={testId}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 40 40'
        fill='none'
        className={className}
        aria-label='Apple Pay'
      >
        <path
          fill='#000'
          d='M36.028 7.5H3.572a16.7 16.7 0 0 0-.338.006 4.904 4.904 0 0 0-.735.064 2.48 2.48 0 0 0-.698.23A2.343 2.343 0 0 0 .775 8.828a2.469 2.469 0 0 0-.23.698c-.044.242-.058.49-.065.734-.003.113-.004.225-.005.338V29.403c0 .113.002.225.005.338.007.244.021.492.065.734.044.246.116.475.23.698A2.336 2.336 0 0 0 1.801 32.2c.224.114.452.187.698.23.242.044.49.059.735.065.112.003.225.004.337.005l.4.001h32.057l.4-.001c.113 0 .225-.002.338-.005.245-.006.492-.021.735-.064a2.47 2.47 0 0 0 .698-.23 2.334 2.334 0 0 0 1.026-1.027c.114-.223.186-.452.23-.698.044-.242.058-.49.064-.734.003-.113.005-.225.005-.337.002-.134.002-.267.002-.401V10.997c0-.133 0-.267-.002-.4 0-.113-.002-.225-.005-.338a4.862 4.862 0 0 0-.064-.734A2.355 2.355 0 0 0 37.5 7.57a4.916 4.916 0 0 0-.735-.064 16.808 16.808 0 0 0-.337-.005l-.4-.001Z'
        />
        <path
          fill='#fff'
          d='M36.028 8.333h.394l.321.006c.187.005.406.015.61.051.177.032.325.08.468.153a1.51 1.51 0 0 1 .661.663c.072.141.12.289.152.467.037.202.047.42.052.609a51.799 51.799 0 0 1 .006.715v18.006l-.001.396c0 .107-.002.213-.005.319a4.135 4.135 0 0 1-.052.61c-.031.177-.08.325-.152.467a1.509 1.509 0 0 1-.663.662 1.63 1.63 0 0 1-.465.152 4.222 4.222 0 0 1-.607.052c-.108.002-.215.004-.325.004l-.394.001H3.575l-.319-.005a4.242 4.242 0 0 1-.61-.052 1.636 1.636 0 0 1-.468-.153 1.493 1.493 0 0 1-.66-.661 1.636 1.636 0 0 1-.153-.469 4.123 4.123 0 0 1-.052-.608 15.556 15.556 0 0 1-.005-.32V10.603c0-.107.002-.214.005-.32.005-.187.015-.405.052-.61.032-.177.08-.325.153-.468a1.503 1.503 0 0 1 .661-.661c.142-.072.29-.12.467-.153a4.16 4.16 0 0 1 .61-.051c.107-.003.214-.004.32-.005l.395-.001h32.057Z'
        />
        <path
          fill='#000'
          d='M11.135 15.909c.334-.419.561-.98.501-1.554-.489.024-1.086.323-1.432.741-.31.359-.585.944-.514 1.494.55.047 1.099-.275 1.445-.681ZM11.63 16.697c-.798-.047-1.476.453-1.857.453s-.965-.429-1.596-.417c-.821.012-1.584.476-2 1.215-.858 1.477-.227 3.67.607 4.873.405.595.893 1.251 1.536 1.228.607-.024.845-.394 1.584-.394.738 0 .952.394 1.595.382.667-.012 1.084-.596 1.489-1.192.465-.679.655-1.334.667-1.37-.012-.012-1.286-.5-1.298-1.966-.012-1.227 1-1.811 1.048-1.847-.572-.846-1.465-.941-1.775-.965ZM18.579 15.037c1.734 0 2.941 1.195 2.941 2.936 0 1.746-1.232 2.948-2.985 2.948h-1.92v3.054h-1.387v-8.938h3.35Zm-1.964 4.72h1.592c1.208 0 1.895-.65 1.895-1.778 0-1.127-.687-1.772-1.889-1.772h-1.598v3.55ZM21.883 22.123c0-1.14.873-1.84 2.422-1.927l1.784-.105v-.502c0-.724-.49-1.158-1.307-1.158-.775 0-1.258.372-1.375.954h-1.264c.074-1.177 1.078-2.044 2.688-2.044 1.58 0 2.59.836 2.59 2.143v4.49h-1.283v-1.07h-.03c-.379.724-1.203 1.182-2.057 1.182-1.276 0-2.168-.793-2.168-1.963Zm4.206-.589v-.514l-1.605.1c-.799.055-1.25.408-1.25.965 0 .57.47.942 1.188.942.936 0 1.666-.644 1.666-1.493ZM28.63 26.372v-1.084c.1.024.322.024.434.024.62 0 .954-.26 1.158-.928 0-.013.118-.397.118-.403l-2.354-6.523h1.45l1.647 5.303h.025l1.648-5.302h1.412l-2.44 6.856c-.558 1.58-1.202 2.088-2.552 2.088-.112 0-.446-.013-.545-.031Z'
        />
      </svg>
    </i>
  )
}
