import * as React from 'react'
import { RestaurantFeaturesResponse } from './IRestaurantFeatures'

export interface RestaurantFeaturesProps {
  children: React.ReactNode
}

export interface IRestaurantFeaturesData {
  data: RestaurantFeaturesResponse
  isLoading: boolean
  isError: boolean
  error: string
}

interface IRestaurantFeaturesContext {
  restaurantFeaturesData: IRestaurantFeaturesData | undefined
  setRestaurantFeaturesData: any
}

const RestaurantFeaturesContext = React.createContext<
  IRestaurantFeaturesContext | undefined
>(undefined)

export const RestaurantFeaturesProvider = ({
  children
}: RestaurantFeaturesProps) => {
  const [restaurantFeaturesData, setRestaurantFeaturesData] =
    React.useState<IRestaurantFeaturesData>()

  const value = React.useMemo(
    () => ({ restaurantFeaturesData, setRestaurantFeaturesData }),
    [restaurantFeaturesData]
  )

  return (
    <RestaurantFeaturesContext.Provider value={value}>
      {children}
    </RestaurantFeaturesContext.Provider>
  )
}

export const useRestaurantFeatures = () => {
  const context = React.useContext(RestaurantFeaturesContext)
  if (context === undefined) {
    throw new Error(
      'useRestaurantFeatures must be used within a RestaurantFeaturesProvider'
    )
  }
  return context
}
