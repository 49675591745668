import * as React from 'react'
import { t } from '@local/translations'
// import { LinearProgressBar } from '@toasttab/buffet-pui-progress-indicators'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import './RewardPoints.css'

export interface RewardPointsProps {
  testId?: string | number
  rewardsBalance?: number
  leftToTarget?: number
  isLoading?: boolean
}

/**
 * RewardPoints component
 */
export const RewardPoints = ({
  testId = 'reward-points',
  rewardsBalance = 0,
  leftToTarget = 0,
  isLoading
}: RewardPointsProps) => {
  // const progressPercent =
  //   (accumulatedPoints / (accumulatedPoints + pointsToUnlockReward)) * 100

  return isLoading ? (
    <div
      data-testid={testId + '-loading-state'}
      className='flex flex-col justify-center items-center w-full'
    >
      <div className='mt-4 mb-6'>
        <Skeleton className='w-52 h-6' />
      </div>
      <div className='mb-4 flex flex-col justify-center items-center'>
        <Skeleton className='w-32 h-4 mb-2' />
        <Skeleton className='w-48 h-6 mb-2' />
        <Skeleton className='w-28 h-4' />
      </div>
      <div className='flex flex-col justify-center mb-6 w-full'>
        <Skeleton className='w-full h-4' />
      </div>
    </div>
  ) : (
    <div data-testid={testId}>
      <div className='mb-4'>
        <h4 className='type-headline-5 font-normal font-effra text-center'>
          {' '}
          {t('loyalty-page-new-version.rewards-points.welcome')}
        </h4>
        <p className='type-overline text-center text-default'>
          {t('loyalty-page-new-version.rewards-points.loyalty-balance')}
        </p>
        <h5 className='type-headline-4 font-effra text-center'>
          {rewardsBalance}
        </h5>
        <p className='type-subhead text-center text-secondary'>
          {t(
            'loyalty-page-new-version.rewards-points.n-points-to-your-next-reward',
            {
              leftToTarget
            }
          )}
        </p>
      </div>
      {/* <div className='flex flex-col justify-center mb-6'>
        <LinearProgressBar
          //@ts-ignore
          testId={testId + '-progress-bar'}
          progressPercent={progressPercent > 100 ? 100 : progressPercent}
        />
      </div> */}
    </div>
  )
}
