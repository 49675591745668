import * as React from 'react'
import cx from 'classnames'

import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { getBuffetConfig } from '@toasttab/buffet-utils'

import { SelectButtonDefault } from './SelectButtonDefault'
import { SelectButtonContained } from './SelectButtonContained'

export type Size =
  | 'lg'
  | 'sm'
  | 'base' // deprecated in favor of 'lg'
  | 'auto'

export interface SelectButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    TestIdentifiable {
  selected?: boolean
  disabled?: boolean
  invalid?: boolean
  inlineBlock?: boolean
  iconLeft?: React.ReactNode
  isOpen?: boolean
  disableFocusShadow?: boolean
  props?: any
  children?: React.ReactNode
  size?: Size
  align?: 'top' | 'center'
  customHeight?: boolean
  containerClassName?: string
  containerRef?: React.Ref<HTMLDivElement>
  transparentBackground?: boolean
  borderRadiusClassName?: string
  changed?: boolean
  label?: React.ReactNode
  hideLabel?: boolean
}

export const SelectButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<SelectButtonProps>
>((props, ref) => {
  const { isContainedInputStyle } = getBuffetConfig()

  if (isContainedInputStyle) {
    return <SelectButtonContained {...props} ref={ref} />
  }

  return <SelectButtonDefault {...props} ref={ref} />
})

export const getToggleButtonSizeClass = (size?: Size) => {
  switch (size) {
    case 'sm':
      return 'my-2'
    case 'lg':
    case 'base':
      return 'my-3'
    case 'auto':
    default:
      return 'my-3 md:my-2'
  }
}

export const getToggleButtonTextColor = (
  selected?: boolean,
  disabled?: boolean
) => {
  if (disabled) {
    return 'text-disabled'
  } else if (selected) {
    return 'text-default'
  } else {
    return 'text-secondary'
  }
}

export const getToggleButtonHeightClass = (size: Size) => {
  switch (size) {
    case 'sm':
      return 'input-h-sm'
    case 'lg':
    case 'base':
      return 'input-h-lg'
    case 'auto':
    default:
      return 'input-h-lg md:input-h-sm'
  }
}

/**
 * Use this class to provide the appropriate padding
 */
export const SelectButtonContents = ({
  size,
  children
}: {
  size?: Size
  children: React.ReactNode
}) => {
  const { isContainedInputStyle } = getBuffetConfig()

  const toggleButtonSizeClass = !isContainedInputStyle
    ? getToggleButtonSizeClass(size)
    : null
  return <div className={cx('truncate', toggleButtonSizeClass)}>{children}</div>
}
