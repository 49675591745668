import * as React from 'react'
import { getInputBorderClassName, getInputClassName } from '../utils'
import { useUniqueId } from '@toasttab/buffet-utils'
import {
  Border,
  HelperText,
  Label,
  LabeledHelperTextProps,
  Prefix,
  Suffix,
  getInputPaddingLeft,
  getInputPaddingRight
} from '@toasttab/buffet-pui-text-base'
import { SharedInputProps } from '../types'

export interface TextInputProps
  extends SharedInputProps,
    LabeledHelperTextProps,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix'> {
  value?: string
  /** Allow the input to be transparent. Will cause a Safari issue with placeholder text. See https://github.com/toasttab/buffet/pull/882. */
  transparentBackground?: boolean
  /**
   * Apply a className to the input element.
   * For example, adding 'w-10' will set the size of the input to 40px,
   * but will allow the label to stay full width.
   * This should not be used to change the visual design of this component.
   */
  inputContainerClassName?: string
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(
    {
      name,
      label,
      helperIconButton,
      onChange,
      onBlur,
      value,
      containerClassName,
      inputContainerClassName,
      className,
      disabled,
      readOnly,
      prefix,
      prefixVariant = Prefix.Variant.bgGray,
      suffix,
      suffixVariant = Suffix.Variant.bgGray,
      size = 'auto',
      autoComplete,
      type = 'text',
      testId,
      invalid,
      helperText,
      errorText,
      preserveHelpSpace,
      transparentBackground,
      changed = false,
      style,
      required,
      ...restProps
    },
    ref
  ) {
    const uniqueTestId = useUniqueId(testId, 'text-input-')
    const uniqueId = useUniqueId(name, 'text-input-')

    return (
      <div
        className={containerClassName}
        data-testid={`${uniqueTestId}-container`}
      >
        <Label
          name={uniqueId}
          disabled={disabled}
          required={required}
          helperIconButton={helperIconButton}
        >
          {label}
        </Label>
        <Border
          disabled={disabled}
          readOnly={readOnly}
          invalid={invalid}
          prefix={prefix}
          prefixVariant={prefixVariant}
          suffix={suffix}
          suffixVariant={suffixVariant}
          testId={uniqueTestId}
          borderRadiusClassName={inputContainerClassName}
          className={getInputBorderClassName({
            size,
            readOnly: readOnly && !disabled,
            transparentBackground,
            changed
          })}
          label={label}
        >
          <input
            ref={ref}
            id={uniqueId}
            name={name}
            data-testid={uniqueTestId}
            style={{
              ...style,
              paddingLeft: getInputPaddingLeft({
                prefix,
                prefixVariant
              }),
              paddingRight: getInputPaddingRight({
                suffix,
                suffixVariant
              })
            }}
            className={getInputClassName({
              disabled,
              readOnly,
              prefix,
              prefixVariant,
              suffix,
              suffixVariant,
              className,
              changed,
              transparentBackground
            })}
            disabled={disabled}
            readOnly={readOnly}
            autoComplete={autoComplete}
            type={type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            aria-invalid={invalid}
            required={required}
            aria-required={required}
            {...restProps}
          />
        </Border>
        <HelperText
          testId={`${uniqueTestId}-helper-text`}
          disabled={disabled}
          invalid={invalid}
          errorText={errorText}
          helperText={helperText}
          preserveHelpSpace={preserveHelpSpace}
        />
      </div>
    )
  }
)

TextInput.displayName = 'TextInput'
