import React, { useRef } from 'react'
import type { Placement } from '@toasttab/buffet-pui-floating-ui-base'
import { NumberInput, NumberInputProps } from '@toasttab/buffet-pui-text-input'
import {
  LabeledHelperTextProps,
  Label,
  HelperText,
  SizeTypes as Size
} from '@toasttab/buffet-pui-text-base'
import {
  countryIsoCodes as defaultCountryIsoCodes,
  CountryIsoCode,
  DefaultStrings as CountryNameStrings,
  getCountry,
  getCountryStrings
} from '@toasttab/buffet-pui-country-utilities'
import { getBuffetConfig } from '@toasttab/buffet-utils'
import { CountrySelect, buildCountryOptions } from './CountrySelect'
import { getPhoneInfo } from './getPhoneInfo'
import {
  makePhoneNumberFromIntlPhoneNumberString,
  phoneNumberPlaceholder,
  formatPhone
} from '@toasttab/buffet-pui-phone-utilities'
import { getErrorState } from './getErrorState'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
import { DefaultStrings, loadStrings, t } from './defaultStrings'

export type PhoneNumber = {
  countryCode?: string
  nationalNumber?: string
  countryIsoCode?: CountryIsoCode
}

export type Errors = {
  countryCode?: keyof DefaultStrings
  nationalNumber?: keyof DefaultStrings
}

export interface PhoneInputProps
  extends LabeledHelperTextProps,
    TestIdentifiable {
  testId?: string | number
  containerClassName?: string
  /**
   * Name for use in label
   */
  name: string
  /**
   * Strings for use in validation messages
   */
  strings?: DefaultStrings
  /**
   * Country strings to match the given countryIsoCodes
   */
  countryNameStrings?: CountryNameStrings
  /**
   * Locale: An optional locale to drive the displayed errors and country names
   */
  locale?: Locale
  /**
   * A label to display above the inputs
   */
  label?: string
  /**
   * A given phone number value
   */
  value: PhoneNumber
  /**
   * A function to receive and handle the output
   */
  onChange: (phone: PhoneNumber) => void
  /**
   * A function called when the phone number input loses focus
   */
  onBlur?: () => void
  /**
   *  An array of supported country codes
   */
  countryIsoCodes?: CountryIsoCode[]
  /**
   * An array of country codes to list above the others
   */
  preferredCountryIsoCodes?: CountryIsoCode[]
  /**
   * Error message keys on a phone number object
   */
  errors?: Errors
  /**
   * Disable the country dropdown, so it is read-only
   */
  disableCountry?: boolean
  /**
   * Disable the entire input combination
   */
  disabled?: boolean
  /**
   * Country dropdown placement
   */
  placement?: Placement
  /**
   *
   */
  size?: Size
  /**
   * Allow the input to be transparent.
   */
  transparentBackground?: boolean
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  testId = `international-telephone`,
  containerClassName,
  name,
  label,
  value = {
    countryCode: '1',
    countryIsoCode: 'US',
    nationalNumber: ''
  },
  countryIsoCodes = defaultCountryIsoCodes,
  preferredCountryIsoCodes = [],
  onChange,
  onBlur,
  helperText,
  helperIconButton,
  errors = {},
  strings,
  countryNameStrings,
  locale,
  disableCountry,
  disabled,
  placement = 'bottom-start',
  size,
  transparentBackground,
  required
}) => {
  if (!strings) {
    loadStrings(locale || getLocale())
  }

  let countryStrings =
    countryNameStrings || getCountryStrings(locale || getLocale())

  const { isContainedInputStyle } = getBuffetConfig()
  const phoneInfo = value.nationalNumber
    ? getPhoneInfo(`+${value.countryCode}${value.nationalNumber}`)
    : undefined

  const intlPhoneInfo = value.nationalNumber
    ? getPhoneInfo(`+${value.nationalNumber}`)
    : undefined

  const intlPhoneNumber =
    !disableCountry &&
    intlPhoneInfo &&
    intlPhoneInfo.isValid &&
    countryIsoCodes.includes(intlPhoneInfo.countryIsoCode)
      ? makePhoneNumberFromIntlPhoneNumberString(
          intlPhoneInfo.countryIsoCode,
          intlPhoneInfo.nationalNumber
        )
      : undefined

  const nationalNumber =
    intlPhoneNumber?.nationalNumber ||
    value.nationalNumber ||
    phoneInfo?.nationalNumber ||
    ''

  const country =
    intlPhoneNumber?.countryIsoCode ||
    value.countryIsoCode ||
    phoneInfo?.countryIsoCode ||
    'US'

  const numberRef = useRef<HTMLInputElement>(null)

  // Handle change events
  const callOnChange = (
    nationalNumber: string,
    countryIsoCode: CountryIsoCode
  ) => {
    if (onChange) {
      onChange({
        countryCode: getCountry(
          countryIsoCode,
          countryStrings as Record<keyof CountryNameStrings, string>
        ).diallingCode,
        countryIsoCode,
        nationalNumber
      })
    }
  }

  const handleCountryChange = (countryIso2: CountryIsoCode) => {
    if (!nationalNumber.length) {
      numberRef.current?.focus()
    }
    callOnChange(nationalNumber, countryIso2)
  }

  const handleNumberChange = (event: NumberInputProps) => {
    const nationalNumber = event.value ? `${event.value}` : ''
    callOnChange(nationalNumber, country)
  }

  const placeholder = phoneNumberPlaceholder(country)

  const { countrySelectInvalid, numberInvalid, errorText } = getErrorState(
    errors,
    strings
  )

  const ariaLabelString =
    (strings && strings['CountrySelect.label.select-country']) ||
    t('CountrySelect.label.select-country')

  // elevation: Note that the z-index classes used here are to manage the focus ring for a compound component (combined dropdown and text input)

  return (
    <div data-testid={testId} className={containerClassName}>
      {label && !isContainedInputStyle && (
        <div>
          <Label
            name={`${name}-input`}
            required={required}
            helperIconButton={helperIconButton}
          >
            {label}
          </Label>
        </div>
      )}
      <div className='flex'>
        <CountrySelect
          testId={`${testId}-country`}
          value={country}
          onChange={(val: string) => handleCountryChange(val as CountryIsoCode)}
          options={buildCountryOptions(
            [...countryIsoCodes],
            preferredCountryIsoCodes,
            countryStrings as Record<keyof CountryNameStrings, string>
          )}
          invalid={countrySelectInvalid}
          disabled={disableCountry || disabled}
          placement={placement}
          size={size}
          transparentBackground={transparentBackground}
          required={required}
          aria-label={ariaLabelString}
          locale={locale || getLocale()}
        />
        <NumberInput
          testId={`${testId}-phoneNumber`}
          containerClassName='w-full -ml-px focus-within:z-5'
          borderRadiusClassName='rounded-input rounded-l-none'
          ref={numberRef}
          name={`${name}-input`}
          label={isContainedInputStyle ? label : undefined}
          type='tel'
          inputMode='tel'
          value={nationalNumber}
          onChange={handleNumberChange}
          onBlur={onBlur}
          invalid={numberInvalid}
          disabled={disabled}
          allowEmptyFormatting={false}
          allowLeadingZeros={true}
          placeholder={placeholder}
          format={(value: string) => formatPhone(value, country, 'national')}
          size={size}
          transparentBackground={transparentBackground}
          required={required}
        />
      </div>
      <HelperText
        testId={`${testId}-helper-text`}
        invalid={countrySelectInvalid || numberInvalid}
        errorText={errorText}
        helperText={helperText}
      />
    </div>
  )
}
