import * as React from 'react'
import { Modal } from '@local/consumer-ui-modal'
import { EmptyPlateIcon } from '@local/assets'
import { t } from '@local/translations'
import { LinkedCardItem } from '../LinkedCardItem'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { ICCardData, useCCLinkService } from '@local/common/src/network'
import { useParams } from 'react-router-dom'
import { IGuestAccountData, useGuestAccount } from '../GuestAccountProvider'
import { triggerAlert } from '../Alert'
import { ConfirmationModal } from './ConfirmationModal'
import { InternalHeader } from './InternalHeader'

export interface CardData {
  linkToken: string
  cardType: string
  last4digits: string
  isExpired: boolean
}

export interface LinkedCardsManagementModalProps {
  testId?: string
  isOpen: boolean
  isLoading: boolean
  onCloseRequest: () => undefined
  cardData: CardData[]
}

/**
 * LinkedCardsManagementModal component
 */
export const LinkedCardsManagementModal = ({
  testId = 'linked-cards-management-modal',
  isOpen,
  isLoading,
  onCloseRequest,
  cardData
}: LinkedCardsManagementModalProps) => {
  const isEmpty = cardData && cardData.length === 0

  const [isConfirming, setIsConfirming] = React.useState(false)
  const [selectedCard, setSelectedCard] = React.useState<CardData | undefined>(
    undefined
  )

  const onDeleteClick = (card: CardData) => {
    setIsConfirming(true)
    setSelectedCard(card)
  }

  const onConfirmingModalDismiss = () => {
    setIsConfirming(false)
    setSelectedCard(undefined)
  }

  const { secureToken } = useParams()
  const { guestAccountData, setGuestAccountData } = useGuestAccount()

  const {
    mutate: DeleteCCLinkMutation,
    isPending: isDeleteLoading,
    isError
  } = useCCLinkService()

  const onDeleteCCLinkError = () => {
    setIsConfirming(false)
    setSelectedCard(undefined)
    triggerAlert(
      t(
        'loyalty-page-new-version.linked-cards-management-modal.confirmation.card-deleted-error'
      ),
      {
        type: 'error'
      }
    )
  }

  const onDeleteCCLinkSuccess = () => {
    setIsConfirming(false)
    setSelectedCard(undefined)
    setGuestAccountData((previous: IGuestAccountData) => ({
      ...previous,
      data: {
        ...previous.data,
        account: {
          ...previous.data.account,
          ccLinks: previous.data.account.ccLinks.filter(
            (card) => card.linkToken !== selectedCard?.linkToken
          )
        }
      }
    }))
    triggerAlert(
      t(
        'loyalty-page-new-version.linked-cards-management-modal.confirmation.card-deleted-successfully'
      )
    )
  }

  const onConfirmingModalConfirm = React.useCallback(() => {
    if (!selectedCard || !secureToken || !guestAccountData) {
      onDeleteCCLinkError()
      return
    }

    const removeCard: ICCardData = {
      secureToken: secureToken,
      linkToken: selectedCard.linkToken,
      restaurantGuid: guestAccountData?.data?.restaurantGuid
    }

    DeleteCCLinkMutation(removeCard, {
      onError: onDeleteCCLinkError,
      onSuccess: onDeleteCCLinkSuccess
    })
  }, [selectedCard, secureToken, guestAccountData])

  return (
    <>
      <Modal
        isOpen-={isOpen}
        onCloseRequest={onCloseRequest}
        testId={testId}
        showHeaderBackground={false}
        showCloseButton={false}
        contentClassName={isEmpty ? 'md:h-[430px]' : 'md:h-[390px]'}
      >
        <Modal.Header>
          <InternalHeader testId={testId} />
          <div className='mt-4'>
            <h4 className='type-headline-4 font-medium font-effra text-center text-default'>
              {t(
                'loyalty-page-new-version.linked-cards-management-modal.title'
              )}
            </h4>
            <p className='text-center type-subhead mt-1 text-default'>
              {t(
                'loyalty-page-new-version.linked-cards-management-modal.description'
              )}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            {isLoading && (
              <div data-testid={testId + '-loading-state'}>
                <div className='flex flex-row justify-between items-center py-3 border-b text-secondary'>
                  <div>
                    <Skeleton className='w-10 h-6 flex-grow-0' />
                  </div>
                  <div>
                    <Skeleton className='h-4 w-52' />
                  </div>
                  <div>
                    <Skeleton className='w-8 h-8 flex-grow-0' />
                  </div>
                </div>
                <div className='flex flex-row justify-between items-center py-3 border-b text-secondary'>
                  <div>
                    <Skeleton className='w-10 h-6 flex-grow-0' />
                  </div>
                  <div>
                    <Skeleton className='h-4 w-52' />
                  </div>
                  <div>
                    <Skeleton className='w-8 h-8 flex-grow-0' />
                  </div>
                </div>
                <div className='flex flex-row justify-between items-center py-3'>
                  <div>
                    <Skeleton className='w-10 h-6 flex-grow-0' />
                  </div>
                  <div>
                    <Skeleton className='h-4 w-52' />
                  </div>
                  <div>
                    <Skeleton className='w-8 h-8 flex-grow-0' />
                  </div>
                </div>
              </div>
            )}
            {!isLoading && !isEmpty && (
              <div>
                {cardData.map((card) => (
                  <LinkedCardItem
                    key={card.cardType + '-' + card.last4digits}
                    cardBrand={card.cardType}
                    cardLastFour={card.last4digits}
                    onClickDelete={() => onDeleteClick(card)}
                    testId={testId + '-linked-card-item'}
                  />
                ))}
              </div>
            )}
            {!isLoading && isEmpty && !isError && (
              <div className='flex flex-col justify-center items-center'>
                <EmptyPlateIcon
                  className='w-64'
                  testId={testId + '-empty-state'}
                />
                <p className='text-center type-default font-semibold'>
                  {t(
                    'loyalty-page-new-version.linked-cards-management-modal.empty-title'
                  )}
                </p>
                <p className='text-center type-default'>
                  {t(
                    'loyalty-page-new-version.linked-cards-management-modal.empty-description'
                  )}
                </p>
              </div>
            )}
          </>
        </Modal.Body>
      </Modal>
      {isConfirming && (
        <ConfirmationModal
          testId={testId + '-confirming-modal'}
          isOpen={isConfirming}
          lastFour={selectedCard?.last4digits || ''}
          cardName={selectedCard?.cardType || ''}
          isDeletingLoading={isDeleteLoading}
          onConfirm={onConfirmingModalConfirm}
          onDismiss={onConfirmingModalDismiss}
        />
      )}
    </>
  )
}
