import { AxiosInstance, AxiosResponse } from 'axios'

export interface IGuestData {
  restaurantGuid?: string
  secureToken?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  email?: string
  birthdayMonth?: string
  birthdayDay?: string
  subscribeToMarketing?: boolean
}

export const updateAccountDataRepository = async (
  instance: AxiosInstance,
  guestData: IGuestData
): Promise<AxiosResponse> => {
  return instance.post('/guest/account/info', guestData)
}
