import * as React from 'react'
import {
  ErrorState,
  RewardTabRewardItem,
  WaysToEarn,
  useRestaurantFeatures
} from '@local/loyalty-page-new-version'

interface RewardItem {
  rewardPrize: string
  rewardRequirement: string
  isLocked?: boolean
  isBirthdayReward?: boolean
}

export interface RewardTabRewardContentProps {
  testId?: string | number
  rewardData?: RewardItem[]
  isLoading?: boolean
  onOpenCheckInLoyaltyModal?: () => void
}

export const RewardTabRewardContent = ({
  testId = 'reward-tab-reward-content',
  rewardData,
  isLoading,
  onOpenCheckInLoyaltyModal
}: RewardTabRewardContentProps) => {
  const redeemCallback = React.useCallback(() => {
    onOpenCheckInLoyaltyModal && onOpenCheckInLoyaltyModal()
  }, [])

  const { restaurantFeaturesData } = useRestaurantFeatures()

  const features = restaurantFeaturesData?.data?.features || []
  const isFeaturesLoading = restaurantFeaturesData?.isLoading
  const isFeaturesError = restaurantFeaturesData?.isError

  const onReloadPage = () => {
    window.location.reload()
  }

  return (
    <>
      <div data-testid={testId}>
        {isLoading && (
          <div
            className='mb-4 grid grid-cols-1 grid-rows-5 gap-4'
            data-testid={testId + '-loading-state'}
          >
            <RewardTabRewardItem isLoading={isLoading} />
            <RewardTabRewardItem isLoading={isLoading} />
            <RewardTabRewardItem isLoading={isLoading} />
          </div>
        )}
        {!isLoading &&
          rewardData &&
          rewardData.length > 0 &&
          rewardData?.map(
            (
              { rewardPrize, rewardRequirement, isLocked, isBirthdayReward },
              index
            ) => (
              <div className='mb-4' key={rewardPrize}>
                <RewardTabRewardItem
                  key={rewardPrize + index}
                  rewardPrize={rewardPrize}
                  rewardRequirement={rewardRequirement}
                  isLocked={isLocked}
                  isBirthdayReward={isBirthdayReward}
                  redeemCallback={redeemCallback}
                />
              </div>
            )
          )}
      </div>
      <div className='w-full mt-4 border-t pt-4'>
        {!isFeaturesLoading && isFeaturesError && (
          <ErrorState callback={onReloadPage} />
        )}
        {!isFeaturesError && (
          <WaysToEarn features={features || []} isLoading={isFeaturesLoading} />
        )}
      </div>
    </>
  )
}
