import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CakeIcon = React.forwardRef(function CakeIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M12 8.73a1.989 1.989 0 001.818-2.14C13.818 5.396 12 2.5 12 2.5s-1.818 2.895-1.818 4.09A1.99 1.99 0 0012 8.73zm0 0v3.315m0 0h6.364a1.84 1.84 0 011.818 1.844V21.5M12 12.045H5.636a1.84 1.84 0 00-1.818 1.844V21.5m0-6.305l.012.08c.034.193.115.374.234.53a1.854 1.854 0 003.056-.001 1.854 1.854 0 013.055 0l.113.15a1.99 1.99 0 003.281 0l.06-.08a1.917 1.917 0 013.161 0 1.925 1.925 0 003.254-.133 1.46 1.46 0 00.138-.517M2 21.5h20'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
