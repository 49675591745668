import * as React from 'react'

export interface WaysToEarnItemProps {
  icon: React.ReactElement
  title: string
  subtitles?: (string | React.ReactElement)[]
  testId?: string | number
}

/**
 * WaysToEarnItem component
 */
export const WaysToEarnItem = ({
  icon,
  title,
  subtitles,
  testId = 'ways-to-earn-item'
}: WaysToEarnItemProps) => {
  return (
    <div
      data-testid={testId}
      className='flex w-full flex-row items-baseline group [&:not(:first-child)]:mt-4'
    >
      <div className='w-8'>{icon}</div>
      <div className='ml-6 mb-4'>
        <div className=''>
          <p className={'text-default type-default font-semibold'}>{title}</p>
        </div>
        <div className=''>
          {subtitles &&
            subtitles.map((subtitle) => (
              <p
                key={
                  title.split(' ').join('-') +
                  String(subtitle).split(' ').join('-')
                }
                className='text-secondary type-default [&:not(:first-child)]:pt-1'
              >
                {subtitle}
              </p>
            ))}
        </div>
      </div>
    </div>
  )
}
