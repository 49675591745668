import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { t } from '@local/translations'
import {
  RewardTabRewardContent,
  RewardTabHistoryContent,
  useGuestAccount
} from '@local/loyalty-page-new-version'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import {
  LoyaltyAccountReward,
  LoyaltyAccountTransaction
} from '../GuestAccountProvider/IGuestAccountData'

export interface RewardsTabsProps {
  testId?: string | number
  onOpenCheckInLoyaltyModal?: () => void
}

type RewardsTab = 'reward' | 'history'

enum RewardType {
  'CASHBACK',
  'ITEM',
  'BIRTHDAY'
}

export const RewardsTabs = ({
  testId = 'rewards-tabs',
  onOpenCheckInLoyaltyModal
}: RewardsTabsProps) => {
  const [activeTab, setActiveTab] = React.useState<RewardsTab>('reward')

  const { guestAccountData } = useGuestAccount()

  const rewardsData = guestAccountData?.data?.account?.rewards?.map(
    (reward: LoyaltyAccountReward) => ({
      rewardPrize:
        reward.rewardLabelMessages.unlockedMessage ||
        reward.rewardLabelMessages.rewardLabelMessage ||
        '',
      rewardRequirement:
        (reward.rewardLabelMessages.unlockedMessage
          ? reward.rewardLabelMessages.rewardLabelMessage
          : reward.rewardLabelMessages.rewardValueMessage) || '',
      isLocked: (reward?.leftToTarget && reward?.leftToTarget > 0) || false,
      isBirthdayReward: reward.type === RewardType.BIRTHDAY.toString()
    })
  )

  const historyData = guestAccountData?.data?.account?.loyaltyTransactions?.map(
    (transaction: LoyaltyAccountTransaction) => ({
      rewardDescription: transaction?.transactionType,
      rewardDate:
        format(new Date(transaction?.transactionDate), Formats.date.medium) ||
        '',
      locationName: 'Location',
      points: transaction?.amountMessage
    })
  )

  return (
    <div data-testid={testId}>
      <div className='bg-surface-secondary rounded-full grid grid-cols-2'>
        <Button
          onClick={() => setActiveTab('reward')}
          variant={activeTab === 'reward' ? 'primary' : 'text-link'}
        >
          {t('loyalty-page-new-version.rewards-tabs.rewards')}
        </Button>
        <Button
          onClick={() => setActiveTab('history')}
          variant={activeTab === 'history' ? 'primary' : 'text-link'}
        >
          {t('loyalty-page-new-version.rewards-tabs.history')}
        </Button>
      </div>
      <div className='mt-6'>
        {activeTab === 'reward' && (
          <RewardTabRewardContent
            rewardData={rewardsData}
            onOpenCheckInLoyaltyModal={onOpenCheckInLoyaltyModal}
            isLoading={guestAccountData?.isLoading}
          />
        )}
        {activeTab === 'history' && (
          <RewardTabHistoryContent
            isLoading={guestAccountData?.isLoading}
            historyData={historyData}
          />
        )}
      </div>
    </div>
  )
}
