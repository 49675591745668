import * as React from 'react'

export interface CardGiftCardIconProps {
  testId?: string | number
  className?: string
}

export const CardGiftCardIcon = ({
  testId,
  className
}: CardGiftCardIconProps) => {
  return (
    <i data-testid={testId}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 42 40'
        fill='none'
        className={className}
        aria-label='Gift Card'
      >
        <rect width='40' height='25' x='1' y='7.5' fill='#fff' rx='2' />
        <rect
          width='41'
          height='26'
          x='.5'
          y='7'
          stroke='#BAB7B5'
          strokeOpacity='.4'
          rx='2.5'
        />
        <path
          fill='#252525'
          fillRule='evenodd'
          d='M14.5 18c0-.644.523-1.166 1.167-1.166h10.666c.645 0 1.167.522 1.167 1.166v1.334c0 .644-.523 1.166-1.167 1.166H15.667a1.167 1.167 0 0 1-1.167-1.166V18Zm1.167-.166A.167.167 0 0 0 15.5 18v1.334c0 .091.075.166.167.166h10.666a.167.167 0 0 0 .167-.166V18a.167.167 0 0 0-.167-.166H15.667Z'
          clipRule='evenodd'
        />
        <path
          fill='#252525'
          fillRule='evenodd'
          d='M21 16.834a.5.5 0 0 1 .5.5V26a.5.5 0 0 1-1 0v-8.666a.5.5 0 0 1 .5-.5Z'
          clipRule='evenodd'
        />
        <path
          fill='#252525'
          fillRule='evenodd'
          d='M23.81 16.048a.5.5 0 0 1 .014.707c-.857.892-2.155 1.079-2.759 1.079a.5.5 0 1 1 0-1c.493 0 1.458-.168 2.038-.772a.5.5 0 0 1 .707-.014Z'
          clipRule='evenodd'
        />
        <path
          fill='#252525'
          fillRule='evenodd'
          d='M21.892 14.053a.5.5 0 0 1 .014.707c-.122.127-.223.326-.294.593a3.85 3.85 0 0 0-.11.852 6.457 6.457 0 0 0 .055 1.034l.002.014v.002a.5.5 0 0 1-.988.157l.493-.078-.493.078v-.003l-.001-.007-.003-.02a6.61 6.61 0 0 1-.039-.343 7.474 7.474 0 0 1-.026-.865c.01-.335.048-.714.144-1.077.095-.359.257-.736.54-1.03a.5.5 0 0 1 .706-.014Z'
          clipRule='evenodd'
        />
        <path
          fill='#252525'
          fillRule='evenodd'
          d='M21.186 14.067a1.818 1.818 0 0 1 2.638 0 1.951 1.951 0 0 1 0 2.688.5.5 0 0 1-.72-.693.951.951 0 0 0 0-1.302.819.819 0 0 0-1.197 0 .5.5 0 1 1-.72-.693ZM18.19 16.048a.5.5 0 0 1 .707.014c.58.604 1.545.771 2.037.771a.5.5 0 1 1 0 1c-.603 0-1.901-.186-2.758-1.078a.5.5 0 0 1 .014-.707Z'
          clipRule='evenodd'
        />
        <path
          fill='#252525'
          fillRule='evenodd'
          d='M20.107 14.053a.5.5 0 0 1 .707.014c.282.294.444.671.54 1.03.095.363.133.742.143 1.077a7.474 7.474 0 0 1-.065 1.207l-.003.021v.007l-.001.002-.494-.077.494.078a.5.5 0 0 1-.988-.156v-.003l.002-.014.008-.06a6.482 6.482 0 0 0 .047-.975 3.845 3.845 0 0 0-.11-.85c-.071-.268-.172-.467-.294-.594a.5.5 0 0 1 .014-.707Z'
          clipRule='evenodd'
        />
        <path
          fill='#252525'
          fillRule='evenodd'
          d='M20.092 14.76a.819.819 0 0 0-1.196 0 .951.951 0 0 0 0 1.302.5.5 0 1 1-.72.693 1.951 1.951 0 0 1 0-2.688 1.818 1.818 0 0 1 2.637 0 .5.5 0 0 1-.721.693ZM16.333 19.5a.5.5 0 0 1 .5.5v5.333c0 .092.075.167.167.167h8a.167.167 0 0 0 .166-.167V20a.5.5 0 0 1 1 0v5.333c0 .645-.522 1.167-1.166 1.167h-8a1.167 1.167 0 0 1-1.167-1.167V20a.5.5 0 0 1 .5-.5Z'
          clipRule='evenodd'
        />
      </svg>
    </i>
  )
}
