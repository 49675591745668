import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CellphoneIcon = React.forwardRef(function CellphoneIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M5.7 18.45h12.6M5.7 5.25h12.6m-5.6 6l3.1-3.1m-1.4 5.4l1.2-1.2m.7 9.7H7.7c-1.1 0-2-.9-2-2v-16c0-1.1.9-2 2-2h8.7c1.1 0 2 .9 2 2v16c-.1 1.1-1 2-2.1 2z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
