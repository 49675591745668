import * as React from 'react'
import { LoyaltyAccountPageResponse } from '../GuestAccountProvider/IGuestAccountData'
import { useCompleteProfileModal } from '@local/loyalty-page-new-version'
import { RestaurantFeaturesResponse } from '../RestaurantFeaturesProvider/IRestaurantFeatures'

export const useHome = (
  guestAccountData?: LoyaltyAccountPageResponse,
  featuresData?: RestaurantFeaturesResponse
) => {
  const [isCompleteProfileModalOpen, setIsCompleteProfileModalOpen] =
    React.useState<boolean>(false)

  const [isCheckInLoyaltyOpen, setIsCheckInLoyaltyOpen] =
    React.useState<boolean>(false)

  const [isCardManagementModalOpen, setIsCardManagementModalOpen] =
    React.useState<boolean>(false)

  const hasCompletedProfile = React.useMemo(() => {
    return (
      guestAccountData?.account?.hasSignedUpForBirthday &&
      guestAccountData?.account?.hasFirstName &&
      guestAccountData?.account?.hasLastName &&
      guestAccountData?.account?.hasEmail &&
      guestAccountData?.account?.hasPhone
    )
  }, [guestAccountData])

  const {
    hasDismissedCompleteProfileModal,
    setHasDismissedCompleteProfileModal
  } = useCompleteProfileModal(guestAccountData)

  const shouldShowCompleteProfilleModal = React.useMemo(() => {
    if (!guestAccountData?.account) return false

    if (hasDismissedCompleteProfileModal) return false

    if (hasCompletedProfile) return false

    return true
  }, [guestAccountData?.account])

  React.useEffect(() => {
    if (shouldShowCompleteProfilleModal) {
      setIsCompleteProfileModalOpen(true)
    } else {
      setIsCompleteProfileModalOpen(false)
    }
  }, [shouldShowCompleteProfilleModal])

  const onBirthdayTreatClick = () => {
    setIsCompleteProfileModalOpen(true)
  }

  const onCheckInLoyaltyClick = () => {
    setIsCheckInLoyaltyOpen(true)
  }

  const onCompleteProfileModalClose = () => {
    setIsCompleteProfileModalOpen(false)
    setHasDismissedCompleteProfileModal(true)
    return undefined
  }

  const onCheckInLoyaltyModalClose = () => {
    setIsCheckInLoyaltyOpen(false)
    return undefined
  }

  const onCardManagementModalClick = () => {
    setIsCardManagementModalOpen(true)
  }

  const onCardManagementModalClose = () => {
    setIsCardManagementModalOpen(false)
    return undefined
  }

  const openTakeOut = () => {
    const newWindow = window.open(
      'https://toasttakeout.com/',
      '_blank',
      'noopener,noreferrer'
    )
    newWindow?.focus()
    if (newWindow) newWindow.opener = null
  }

  const onAddBalanceClick = (secureToken: string) => {
    const newWindow = window.open(
      `/loyaltycafe/addvalue/${secureToken}`,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }

  const hasBirthdayTreatAvailable = React.useMemo(() => {
    const features = featuresData?.features
    if (features) {
      return features.some((feature) => feature.tags.includes('BIRTHDAY'))
    }
    return false
  }, [featuresData])

  return {
    isCompleteProfileModalOpen,
    setIsCompleteProfileModalOpen,
    isCheckInLoyaltyOpen,
    setIsCheckInLoyaltyOpen,
    isCardManagementModalOpen,
    setIsCardManagementModalOpen,
    hasCompletedProfile,
    shouldShowCompleteProfilleModal,
    onBirthdayTreatClick,
    onCheckInLoyaltyClick,
    onCompleteProfileModalClose,
    onCheckInLoyaltyModalClose,
    onCardManagementModalClick,
    onCardManagementModalClose,
    openTakeOut,
    onAddBalanceClick,
    hasBirthdayTreatAvailable
  }
}
