import { PhoneNumberFormats } from '../phoneNumberFormatsByCountry'
import { removeLeadingZero, addLeadingZero } from '../utils'

export const IN: PhoneNumberFormats = {
  placeholder: '91234 56789',

  /**
     In India, both mobile and landline numbers are 10 digits long.
     Landline numbers have 2-4 digit STD codes followed by 6-8 digit subscriber numbers.
     Since there's no strict rule distinguishing mobile from landline, a common formatting approach is used.
     Specific landline formatting can be added if needed.
     */

  format: (num: string) => {
    if (num.startsWith('0')) return '###### #####'
    return '##### #####'
  },

  phoneNumberMinLength: (num: string) => {
    if (num.startsWith('0')) return 11
    return 10
  },

  makeNationalNumberIntlCompatible: removeLeadingZero,
  makeNationalNumberLocal: addLeadingZero
}
