import * as React from 'react'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
export interface HistoryTabRewardItemProps {
  testId?: string | number
  rewardDescription: string
  rewardDate: string
  locationName: string
  points: string
  isLoading?: boolean
}

/**
 * HistoryTabRewardItem component
 */
export const HistoryTabRewardItem = ({
  testId = 'history-tab-reward-item',
  rewardDescription,
  rewardDate,
  points,
  isLoading
}: HistoryTabRewardItemProps) => {
  return isLoading ? (
    <div
      data-testid={testId + '-loading-state'}
      className='border-b-gray-25 border-b-2 last:border-b-0 py-2 mt-2'
    >
      <div className='flex flex-row justify-between mb-2'>
        <Skeleton className='w-2/3 h-4' />
        <Skeleton className='w-1/4 h-4' />
      </div>
      <Skeleton className='w-3/4 h-4' />
    </div>
  ) : (
    <div
      data-testid={testId}
      className='border-b-gray-25 border-b-2 last:border-b-0 py-2'
    >
      <div className='flex flex-row justify-between'>
        <p className='type-default font-semibold'>{rewardDescription}</p>
        <p className='text-right type-default font-semibold'>{points}</p>
      </div>
      <p className='type-subhead text-secondary'>
        {rewardDate}
        {/* ・ {locationName} //Location name needs more clarification */}
      </p>
    </div>
  )
}
