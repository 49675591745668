import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ExpandMoreIcon } from '@toasttab/buffet-pui-icons'
import { HistoryTabRewardItem } from '../HistoryTabRewardItem'
import { t } from '@local/translations'
import { HistoryTabEmptyState } from '../HistoryTabEmptyState'
import { uniqueKey } from '@local/common'

export interface RewardTabHistoryContentProps {
  testId?: string | number
  historyData?: HistoryItem[]
  isLoading?: boolean
}

interface HistoryItem {
  rewardDescription: string
  rewardDate: string
  locationName: string
  points: string
}

/**
 * RewardTabHistoryContent component
 */
export const RewardTabHistoryContent = ({
  testId = 'reward-tab-history-content',
  historyData = [],
  isLoading
}: RewardTabHistoryContentProps) => {
  const hasData = !isLoading && historyData && historyData.length > 0

  const [showingUpto, setShowingUpto] = React.useState<number>(5)

  const onShowMoreClicked = () => {
    if (historyData.length > showingUpto) {
      setShowingUpto(showingUpto + 5)
    }
  }

  return (
    <div data-testid={testId}>
      <div>
        {!hasData && <HistoryTabEmptyState />}
        {hasData &&
          historyData
            ?.map(({ rewardDescription, rewardDate, locationName, points }) => (
              <HistoryTabRewardItem
                key={rewardDescription + rewardDate + uniqueKey()}
                rewardDescription={rewardDescription}
                rewardDate={rewardDate}
                locationName={locationName}
                points={points}
                testId={testId + '-history-item'}
                isLoading={isLoading}
              />
            ))
            .slice(0, showingUpto)}
      </div>
      {!isLoading && historyData && historyData.length > 5 && (
        <div className='flex flex-row gap-4 justify-center'>
          {showingUpto < historyData.length && (
            <Button
              onClick={onShowMoreClicked}
              iconRight={<ExpandMoreIcon />}
              variant='text-link'
              testId={testId + '-show-more-button'}
            >
              {t(
                'loyalty-page-new-version.reward-tab-history-content.show-more'
              )}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
