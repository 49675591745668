import * as React from 'react'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { getBuffetConfig } from '@toasttab/buffet-utils'

import { BorderDefault } from './BorderDefault'
import { BorderContained } from './BorderContained'
import { Variant } from '../variant'

export interface BorderProps extends TestIdentifiable {
  invalid?: boolean
  disabled?: boolean
  readOnly?: boolean
  /** A string for bgGray and textOnly variants, or node for the icon variant */
  prefix?: React.ReactNode
  /** A string for bgGray and textOnly variants, or node for the icon variant */
  suffix?: React.ReactNode
  /** controls how the prefix renders */
  prefixVariant?: Variant
  /** controls how the suffix renders */
  suffixVariant?: Variant
  /** Used for constructing a search input */
  roundedFull?: boolean
  className?: string
  borderRadiusClassName?: string
  label?: React.ReactNode
  isTextArea?: boolean
}

export const Border = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BorderProps>
>((props, ref) => {
  const { isContainedInputStyle } = getBuffetConfig()

  if (isContainedInputStyle) {
    return <BorderContained {...props} ref={ref} />
  }

  return <BorderDefault {...props} ref={ref} />
})
