import * as React from 'react'

export interface CardMasterIconProps {
  testId?: string | number
  className?: string
}

export const CardMasterIcon = ({ testId, className }: CardMasterIconProps) => {
  return (
    <i data-testid={testId}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 40 40'
        className={className}
        aria-label='Master Card'
      >
        <path fill='#FF5F00' d='M24.233 11.722h-9.464V28.73h9.464V11.722Z' />
        <path
          fill='#EB001B'
          d='M15.37 20.226a10.798 10.798 0 0 1 4.13-8.504 10.816 10.816 0 1 0 0 17.008 10.798 10.798 0 0 1-4.13-8.504Z'
        />
        <path
          fill='#F79E1B'
          d='M37 20.226a10.815 10.815 0 0 1-17.5 8.504 10.818 10.818 0 0 0 0-17.008A10.816 10.816 0 0 1 37 20.226ZM35.967 26.928v-.348h.14v-.071h-.357v.07h.14v.35h.077Zm.694 0v-.42h-.11l-.125.29-.126-.29h-.11v.42h.077v-.317l.118.273h.08l.119-.273v.317h.077Z'
        />
      </svg>
    </i>
  )
}
