import * as React from 'react'

export interface CardToastCashIconProps {
  testId?: string | number
  className?: string
}

export const CardToastCashIcon = ({
  testId,
  className
}: CardToastCashIconProps) => {
  return (
    <i data-testid={testId}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 40 40'
        className={className}
        fill='none'
        aria-label='Toast Cash'
      >
        <rect width='40' height='25' y='7.5' fill='#FF4C00' rx='2' />
        <path
          fill='#fff'
          d='M26.989 22.618c0-.909-.022-1.828-.12-2.726-.066-.613-.274-1.402-.92-1.555.076.022.197.022.251-.011.756-.405.701-1.39.428-2.114-.35-.941-1.194-1.61-2.016-2.047-2.968-1.588-6.955-1.457-9.835.35-.756.471-1.622 1.39-1.238 2.42.098.252.263.482.449.679.088.088.285.307.285.307-.384.536-.668 1.018-.855 1.664-.547 1.938-.449 3.187-.306 4.752.087.964.405 2.41 1.511 2.574.898.12 1.862-.175 2.76-.285.986-.12 1.983-.306 2.98-.274 1.402.033 2.793.373 4.205.395.745.01 1.983.076 2.202-.92.208-.942.219-1.928.23-2.891-.011-.11-.011-.208-.011-.318Zm-1.61 1.643c-.35.8-1.336.712-2.026.646-1.03-.088-2.049-.34-3.09-.372-.92-.033-1.828.153-2.715.35-.669.153-1.6.537-2.213.022-.668-.58-.789-1.566-.8-2.398-.01-.723.1-1.533.22-2.234.076-.46.23-.898.394-1.325.24-.624.591-.997.613-.975-.175-.186-.394-.284-.515-.547-.46-.975.69-1.796 1.315-2.08 2.19-.997 5.257-.899 7.327.383.647.394 1.895 1.774.723 2.452.723.35.91 1.391.986 2.136a13.074 13.074 0 0 1-.11 3.58c-.021.12-.054.252-.109.362Z'
        />
      </svg>
    </i>
  )
}
