import * as React from 'react'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  CardAmexIcon,
  CardGooglePayIcon,
  CardMasterIcon,
  CardVisaIcon,
  CardDiscoverIcon,
  CardC2PIcon,
  CardApplePayIcon,
  CardGenericIcon,
  CardGiftCardIcon,
  CardToastCashIcon
} from '@local/assets'
import { t } from '@local/translations'

export interface LinkedCardItemProps {
  testId?: string | number
  cardBrand: string
  cardLastFour: string
  onClickDelete: () => void
}

/**
 * LinkedCardItem component
 */
export const LinkedCardItem = ({
  testId = 'linked-card-item',
  cardBrand,
  cardLastFour,
  onClickDelete
}: LinkedCardItemProps) => {
  const cardIcon = React.useMemo(() => {
    switch (cardBrand) {
      case 'VISA':
        return <CardVisaIcon className='w-10' testId={testId + '-visa-card'} />
      case 'MASTERCARD':
        return (
          <CardMasterIcon
            className='w-10'
            testId={testId + '-mastercard-card'}
          />
        )
      case 'GOOGLEPAY':
        return (
          <CardGooglePayIcon
            className='w-10'
            testId={testId + '-googlepay-card'}
          />
        )
      case 'AMEX':
        return <CardAmexIcon className='w-10' testId={testId + '-amex-card'} />
      case 'DISCOVER':
        return (
          <CardDiscoverIcon
            className='w-10'
            testId={testId + '-discover-card'}
          />
        )
      case 'C2P':
        return <CardC2PIcon className='w-10' testId={testId + '-c2p-card'} />
      case 'APPLEPAY':
        return (
          <CardApplePayIcon
            className='w-10'
            testId={testId + '-applepay-card'}
          />
        )
      case 'GIFTCARD':
        return (
          <CardGiftCardIcon
            className='w-10'
            testId={testId + '-giftcard-card'}
          />
        )
      case 'TOASTCASH':
        return (
          <CardToastCashIcon
            className='w-10'
            testId={testId + '-toastcash-card'}
          />
        )
      default:
        return (
          <CardGenericIcon className='w-10' testId={testId + '-generic-card'} />
        )
    }
  }, [cardBrand])

  const cardBrandName = React.useMemo(() => {
    switch (cardBrand) {
      case 'VISA':
        return t('loyalty-page-new-version.linked-card-item.visa')
      case 'MASTERCARD':
        return t('loyalty-page-new-version.linked-card-item.mastercard')
      case 'GOOGLEPAY':
        return t('loyalty-page-new-version.linked-card-item.googlepay')
      case 'AMEX':
        return t('loyalty-page-new-version.linked-card-item.amex')
      case 'DISCOVER':
        return t('loyalty-page-new-version.linked-card-item.discover')
      case 'C2P':
        return t('loyalty-page-new-version.linked-card-item.c2p')
      case 'APPLEPAY':
        return t('loyalty-page-new-version.linked-card-item.applepay')
      case 'GIFTCARD':
        return t('loyalty-page-new-version.linked-card-item.giftcard')
      case 'TOASTCASH':
        return t('loyalty-page-new-version.linked-card-item.toastcash')
      default:
        return t('loyalty-page-new-version.linked-card-item.generic')
    }
  }, [cardBrand])

  return (
    <div
      data-testid={testId}
      className='flex flex-row justify-between items-center border-b last:border-b-0 text-secondary py-2'
    >
      <div className='flex-grow-0 mr-3'>{cardIcon}</div>
      <div className='flex-grow type-subhead text-default mr-3'>
        {cardBrandName} ••••{cardLastFour}
      </div>
      <div className='flex-grow-0'>
        <IconButton
          testId={testId + '-delete-button'}
          icon={<DeleteIcon className='text-default' />}
          onClick={onClickDelete}
        />
      </div>
    </div>
  )
}
