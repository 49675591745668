import 'systemjs-webpack-interop/auto-public-path'
// IMPORTANT: Do not move or remove the above import. It must be at the top of this file. See https://www.npmjs.com/package/systemjs-webpack-interop#newer-versions-of-webpack
import {
  banquetSingleSpaReact,
  BanquetSingleSpaReact
} from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { loadTranslations } from '@local/translations'
import { themes } from '@local/themes'
import { App } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-loyalty-account',
  rootComponent: App,
  sentry: {
    publicKey: 'ac06fc67d66dc67d3c97202d2e3b2d99@o37442',
    projectId: '4508337661476864',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },
  theme: new ThemeManager(() => {
    return themes.next
  })
})

export const bootstrap: BanquetSingleSpaReact['bootstrap'] = [
  loadTranslations,
  ...reactLifecycles.bootstrap
]
export const mount: BanquetSingleSpaReact['mount'] = reactLifecycles.mount
export const unmount: BanquetSingleSpaReact['unmount'] = reactLifecycles.unmount
export const name = 'loyalty-account-page-spa'
