import * as React from 'react'
import cx from 'classnames'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { WarningFilledIcon } from '@toasttab/buffet-pui-icons'
import { getBuffetConfig } from '@toasttab/buffet-utils'
import { LabelProps } from '../Label'

const getTextColor = (disabled: boolean, invalid: boolean): string => {
  if (disabled) {
    return 'text-disabled'
  } else if (invalid) {
    return 'text-error'
  } else {
    return 'text-secondary'
  }
}

export interface HelperTextProps extends TestIdentifiable {
  /** The text or the node content to display as helper text. */
  helperText?: React.ReactNode
  /** Whether or not the form input is disabled */
  disabled?: boolean
  /** Whether or not the form input has an error */
  invalid?: boolean
  /** The error message to show if the input is not valid; displays instead of the helper text */
  errorText?: React.ReactNode
  /** Whether or not there should be extra line of space preserved, if no helper text */
  preserveHelpSpace?: boolean
}

export interface LabeledHelperTextProps extends HelperTextProps {
  /** The name of the form input this label belongs to (the Label component's "name" prop is rendered as htmlFor on the resulting label element) */
  name?: string
  /** The label to display above the input */
  label?: React.ReactNode
  /** Use this if you absolutely can't have a visible label (so that screen readers still have a label) */
  'aria-label'?: string
  /** The html id of the component */
  id?: string
  /** Determines whether a field is marked as required */
  required?: boolean
  /** The icon to display as helper next to the label */
  helperIconButton?: LabelProps['helperIconButton']
}

const ErrorTextContent = ({
  className,
  resolvedHelperText
}: {
  className: string
  resolvedHelperText: React.ReactNode
}) => {
  return (
    <div className={className}>
      <WarningFilledIcon
        accessibility='decorative'
        className='mr-1'
        size='xs'
      />
      {resolvedHelperText}
    </div>
  )
}

const HelperTextContent = ({
  className,
  resolvedHelperText,
  preserveHelpSpace
}: {
  className: string
  resolvedHelperText?: React.ReactNode
  preserveHelpSpace?: boolean
}) => {
  const textToRender = !!resolvedHelperText ? (
    resolvedHelperText
  ) : preserveHelpSpace ? (
    <>&nbsp;</>
  ) : (
    ''
  )
  if (!textToRender) {
    return null
  }
  // eslint-disable-next-line @toasttab/buffet/no-hardcoded-strings
  return <div className={className}>{textToRender}</div>
}

export const HelperText = ({
  testId,
  helperText,
  errorText,
  disabled = false,
  invalid = false,
  preserveHelpSpace = false
}: HelperTextProps) => {
  const { isContainedInputStyle } = getBuffetConfig()
  const textColor = getTextColor(disabled, invalid)
  const resolvedHelperText = (invalid && errorText) || helperText
  const className = cx(
    'type-caption pt-1 flex items-start',
    textColor,
    isContainedInputStyle && 'mt-1'
  )

  return (
    <div data-testid={testId}>
      <div role='alert'>
        {invalid && !!resolvedHelperText && (
          <ErrorTextContent
            className={className}
            resolvedHelperText={resolvedHelperText}
          />
        )}
      </div>
      {!invalid && (
        <HelperTextContent
          className={className}
          resolvedHelperText={resolvedHelperText}
          preserveHelpSpace={preserveHelpSpace}
        />
      )}
    </div>
  )
}
