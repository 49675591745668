import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { getGuestAccountDataRepository } from '../repositories'
import { LoyaltyAccountPageResponse } from '@local/loyalty-page-new-version/src/GuestAccountProvider/IGuestAccountData'

interface LoyaltyAccountPageUseQueryResponse {
  data?: LoyaltyAccountPageResponse
}

export const useGuestAccountService = (
  secureToken?: string
): UseQueryResult<LoyaltyAccountPageUseQueryResponse, any> => {
  return useQuery({
    queryKey: ['guestAccountData', secureToken],
    queryFn: getGuestAccountDataRepository,
    enabled: !!secureToken
  })
}
