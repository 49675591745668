import * as React from 'react'
import { LoyaltyAccountPageResponse } from './IGuestAccountData'

export interface GuestAccountProviderProps {
  children: React.ReactNode
}

export interface IGuestAccountData {
  data: LoyaltyAccountPageResponse
  isLoading: boolean
  isError: boolean
  error: string
}

export interface IGuestAccountContext {
  guestAccountData: IGuestAccountData | undefined
  setGuestAccountData: any
}

const GuestAccountContext = React.createContext<
  IGuestAccountContext | undefined
>(undefined)

export const GuestAccountProvider = ({
  children
}: GuestAccountProviderProps) => {
  const [guestAccountData, setGuestAccountData] =
    React.useState<IGuestAccountData>()

  const value = React.useMemo(
    () => ({ guestAccountData, setGuestAccountData }),
    [guestAccountData]
  )

  return (
    <GuestAccountContext.Provider value={value}>
      {children}
    </GuestAccountContext.Provider>
  )
}

export const useGuestAccount = () => {
  const context = React.useContext(GuestAccountContext)
  if (context === undefined) {
    throw new Error(
      'useGuestAccount must be used within a GuestAccountProvider'
    )
  }
  return context
}
