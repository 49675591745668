import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { t } from '@local/translations'

export const ProgramDescription = ({
  programDescription
}: {
  programDescription: string
}) => {
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] =
    React.useState(false)

  const programDescriptionCutPoint = 75

  const isCollapsable = programDescription.length > programDescriptionCutPoint

  React.useEffect(() => {
    if (programDescription.length > programDescriptionCutPoint) {
      setIsDescriptionCollapsed(true)
    }
  }, [programDescription])

  const onShowMoreClicked = () => {
    setIsDescriptionCollapsed((previous) => !previous)
  }

  const showProgramDescription = (
    programDescription: string,
    isCollapsed: boolean
  ): string => {
    if (isCollapsed) {
      return programDescription.slice(0, programDescriptionCutPoint)
    }
    return programDescription
  }

  return (
    <p className='type-default text-secondary text-center px-4'>
      {showProgramDescription(programDescription, isDescriptionCollapsed)}
      {isCollapsable && isDescriptionCollapsed && '...'}{' '}
      {isCollapsable && (
        <Button onClick={onShowMoreClicked} variant='text-link' size='sm'>
          {isDescriptionCollapsed
            ? t('loyalty-page-new-version.header.program-description.show-more')
            : t(
                'loyalty-page-new-version.header.program-description.show-less'
              )}
        </Button>
      )}
    </p>
  )
}
