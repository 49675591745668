import * as React from 'react'

export interface CardC2PIconProps {
  testId?: string | number
  className?: string
}

export const CardC2PIcon = ({ testId, className }: CardC2PIconProps) => {
  return (
    <i data-testid={testId}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 42 40'
        fill='none'
        className={className}
        aria-label='C2P'
      >
        <rect width='40' height='25' x='1' y='7.5' fill='#fff' rx='2' />
        <rect
          width='41'
          height='26'
          x='.5'
          y='7'
          stroke='#BAB7B5'
          strokeOpacity='.4'
          rx='2.5'
        />
        <path
          fill='#000'
          d='M26.301 14.16a.578.578 0 0 0-1.068.293c0 .166.073.314.186.419l4.338 5.18-4.29 5.082h-1.853l4.055-4.647.007-.01a.587.587 0 0 0 0-.81c-.003-.004-.003-.007-.007-.01l-4.844-5.487-.003-.004a.58.58 0 0 0-.496-.277h-3.4a.571.571 0 0 0-.576.568.45.45 0 0 0 .066.263l4.506 5.376-3.838 4.535c-.024.023-.355.486-.937.486h-4.603c-.623 0-1.14-.48-1.14-1.118v-7.867c0-.554.517-1.125 1.154-1.125h2.87c.337 0 .575-.226.575-.557a.557.557 0 0 0-.575-.577l-2.897.013a2.261 2.261 0 0 0-2.281 2.239v7.878c0 1.212 1.085 2.248 2.28 2.248h4.603c.231 0 .445-.033.634-.088.028-.006.055-.016.08-.023a2.524 2.524 0 0 0 1.088-.706l4.32-4.96c.203-.23.23-.56.004-.807l-.007-.01-4.09-4.646h1.888l4.272 5.061-4.496 5.342s0 .007.007.013a.563.563 0 0 0-.065.264c0 .314.258.567.575.567h3.4a.58.58 0 0 0 .496-.277l.004-.003 4.84-5.49.007-.01c.227-.247.2-.578-.003-.808L26.3 14.16Z'
        />
      </svg>
    </i>
  )
}
