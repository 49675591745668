import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ICCardData, ccLinkRepository } from '@local/common/src/network'
import { AxiosInstance } from 'axios'

export const useCCLinkService = () => {
  const queryClient = useQueryClient()

  const instance = queryClient.getDefaultOptions()?.mutations?.meta
    ?.instance as AxiosInstance

  return useMutation({
    mutationFn: (card: ICCardData) => {
      return ccLinkRepository(card, instance)
    }
  })
}
