import * as React from 'react'
import { WaysToEarnItem } from '../WaysToEarnItem'
import { CakeIcon, DiamondIcon, TagIcon } from '@toasttab/buffet-pui-icons'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export interface IRestaurantFeature {
  message: string
  description: string
  tags: string[]
}

export interface WaysToEarnProps {
  testId?: string | number
  features: IRestaurantFeature[]
  isLoading?: boolean
}

const IconSelector = ({
  testId,
  tags
}: {
  testId?: string | number
  tags: string[]
}) => {
  const checkIfIsAccural = (tags: string[]) => tags.includes('ACCRUAL')
  const checkIfIsBirthday = (tags: string[]) => tags.includes('BIRTHDAY')

  const isAccural = checkIfIsAccural(tags)
  const isBirthday = checkIfIsBirthday(tags)

  if (isAccural) {
    return <DiamondIcon testId={testId + '-diamond-icon'} size='md' />
  } else if (isBirthday) {
    return <CakeIcon testId={testId + '-cake-icon'} size='md' />
  } else {
    return <TagIcon testId={testId + '-tag-icon'} size='md' />
  }
}

export const WaysToEarn = ({
  testId,
  features,
  isLoading
}: WaysToEarnProps) => {
  return (
    <div data-testid={testId}>
      {isLoading && (
        <div>
          <div className='flex flex-row items-center gap-2'>
            <Skeleton className='w-8 h-8' />
            <Skeleton className='w-52 h-4' />
          </div>
          <div className='flex flex-row items-center gap-2 mt-4'>
            <Skeleton className='w-8 h-8' />
            <Skeleton className='w-64 h-4' />
          </div>
          <div className='flex flex-row items-center gap-2 mt-4'>
            <Skeleton className='w-8 h-8' />
            <Skeleton className='w-56 h-4' />
          </div>
        </div>
      )}
      {!isLoading &&
        features.map(({ message, tags }: IRestaurantFeature, i: number) => {
          return (
            <WaysToEarnItem
              key={message.split(' ').join('-')}
              testId={`feature-${i}`}
              icon={<IconSelector tags={tags} testId={testId} />}
              title={message}
              subtitles={[]}
            />
          )
        })}
    </div>
  )
}
