import * as React from 'react'
import {
  ToastContainer,
  toast as triggerToast,
  Slide,
  ToastOptions
} from 'react-toastify'

export const triggerAlert = (
  message: string,
  options?: ToastOptions<unknown>
) => {
  triggerToast(message, options)
}

export const Alert = () => {
  return (
    <ToastContainer
      position='bottom-center'
      autoClose={5000}
      hideProgressBar
      closeButton={false}
      pauseOnHover
      draggable={false}
      theme='dark'
      transition={Slide}
    />
  )
}
