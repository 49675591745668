import * as React from 'react'

export interface CardGooglePayIconProps {
  testId?: string | number
  className?: string
}

export const CardGooglePayIcon = ({
  testId,
  className
}: CardGooglePayIconProps) => {
  return (
    <i data-testid={testId}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 40 40'
        fill='none'
        className={className}
        aria-label='Google Pay'
      >
        <g clipPath='url(#a)'>
          <path
            fill='#fff'
            d='M29.23 9.404H10.58C4.753 9.404-.016 14.172-.016 20S4.753 30.596 10.58 30.596h18.65c5.827 0 10.595-4.768 10.595-10.596S35.057 9.404 29.23 9.404Z'
          />
          <path
            fill='#3C4043'
            d='M29.23 10.262c1.308 0 2.58.26 3.777.768a9.82 9.82 0 0 1 5.192 5.192A9.61 9.61 0 0 1 38.967 20a9.61 9.61 0 0 1-.768 3.777 9.822 9.822 0 0 1-5.192 5.192 9.613 9.613 0 0 1-3.778.769H10.58c-1.308 0-2.58-.26-3.777-.769a9.823 9.823 0 0 1-5.192-5.192A9.612 9.612 0 0 1 .843 20c0-1.309.26-2.58.768-3.778a9.823 9.823 0 0 1 5.192-5.192 9.612 9.612 0 0 1 3.777-.768h18.65Zm0-.858H10.58C4.753 9.404-.016 14.172-.016 20S4.753 30.596 10.58 30.596h18.65c5.827 0 10.595-4.768 10.595-10.596S35.057 9.404 29.23 9.404Z'
          />
          <path
            fill='#3C4043'
            d='M18.983 20.752v3.205h-1.017v-7.915h2.697c.683 0 1.266.228 1.743.683.487.456.73 1.012.73 1.67 0 .672-.243 1.228-.73 1.679-.472.45-1.055.673-1.743.673h-1.68v.005Zm0-3.735v2.76h1.7c.403 0 .742-.138 1.007-.408.27-.27.408-.599.408-.97a1.32 1.32 0 0 0-.408-.958c-.265-.281-.598-.419-1.006-.419h-1.701v-.005ZM25.797 18.362c.752 0 1.345.202 1.78.604.434.403.651.954.651 1.653v3.338h-.97v-.752h-.042c-.418.62-.98.927-1.68.927-.598 0-1.096-.175-1.498-.53a1.691 1.691 0 0 1-.604-1.325c0-.561.211-1.006.635-1.335.424-.333.991-.498 1.696-.498.604 0 1.102.112 1.488.334v-.233c0-.355-.137-.652-.418-.9-.28-.25-.61-.371-.986-.371-.566 0-1.017.238-1.345.72l-.896-.561c.493-.716 1.224-1.07 2.189-1.07Zm-1.314 3.931c0 .265.11.488.339.663.222.175.487.265.79.265.428 0 .81-.16 1.143-.477.334-.318.504-.689.504-1.118-.318-.25-.758-.376-1.325-.376-.413 0-.757.1-1.033.296-.28.207-.418.456-.418.747ZM33.76 18.537l-3.391 7.799h-1.05l1.262-2.729-2.236-5.07h1.107l1.61 3.889h.022l1.568-3.889h1.107Z'
          />
          <path
            fill='#4285F4'
            d='M14.938 20.106c0-.332-.03-.649-.085-.954h-4.264V20.9h2.455c-.1.583-.42 1.078-.91 1.409v1.134h1.461c.853-.79 1.342-1.957 1.342-3.337Z'
          />
          <path
            fill='#34A853'
            d='M12.133 22.308c-.407.275-.93.436-1.544.436-1.184 0-2.188-.798-2.548-1.874H6.533v1.17a4.539 4.539 0 0 0 4.056 2.5c1.226 0 2.256-.404 3.006-1.098l-1.462-1.134Z'
          />
          <path
            fill='#FABB05'
            d='M7.9 20.003c0-.302.05-.594.141-.869v-1.17H6.533a4.515 4.515 0 0 0-.482 2.039c0 .733.174 1.425.482 2.038l1.508-1.17a2.736 2.736 0 0 1-.142-.868Z'
          />
          <path
            fill='#E94235'
            d='M10.59 17.26c.668 0 1.268.231 1.74.682l1.296-1.295a4.36 4.36 0 0 0-3.037-1.182 4.54 4.54 0 0 0-4.056 2.5l1.508 1.17c.36-1.076 1.364-1.874 2.548-1.874Z'
          />
        </g>
        <defs>
          <clipPath id='a'>
            <path fill='#fff' d='M0 9.404h40v21.192H0z' />
          </clipPath>
        </defs>
      </svg>
    </i>
  )
}
