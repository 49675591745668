import { Modal, ModalSimpleHeader } from '@local/consumer-ui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import { t } from '@local/translations'
import { QRCodeSVG } from 'qrcode.react'
import './CheckInLoyaltyModal.css'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export interface CheckInLoyaltyModalProps {
  testId?: string
  isOpen: boolean
  onCloseRequest: () => undefined
  isLoading?: boolean
  membershipNumber: string
  email?: string
  phoneNumber?: string
}

/**
 * CheckInLoyaltyModal component
 */
export const CheckInLoyaltyModal = ({
  testId = 'linked-cards-management-modal',
  isOpen,
  onCloseRequest,
  membershipNumber,
  phoneNumber,
  email,
  isLoading
}: CheckInLoyaltyModalProps) => {
  const emailOrPhoneNumber = phoneNumber ? phoneNumber : email

  return (
    <Modal
      isOpen-={isOpen}
      onCloseRequest={onCloseRequest}
      testId={testId}
      showHeaderBackground={false}
      showCloseButton={false}
      contentClassName='md:h-[620px]'
    >
      <Modal.Header>
        <ModalSimpleHeader testId={testId + '-header-content'} />
      </Modal.Header>
      <Modal.Body>
        <div className='flex flex-col justify-start'>
          <div className='w-full'>
            <h3 className='type-headline-4 text-center mt-4'>
              {t('loyalty-page-new-version.check-in-loyalty-modal.title')}
            </h3>
            <p className='type-subhead text-center mt-1 mb-4'>
              {t('loyalty-page-new-version.check-in-loyalty-modal.description')}
            </p>
          </div>
          <div className='w-full flex-row justify-center items-center flex'>
            <div className='border border-b-0 rounded-lg rounded-b-none w-56 h-64'>
              {/* <div className='relative'>
                <DiamondBlink className='w-14 absolute top-0 right-0' />

                <div className='relative pt-6 pb-4 flex flex-col justify-center items-center'>
                  {isLoading ? (
                    <Skeleton className='w-3/5 h-4' />
                  ) : (
                    <p className='type-default font-semibold text-center line-clamp-1'>
                      {name}
                    </p>
                  )}
                  {isLoading ? (
                    <Skeleton className='w-4/5 h-3 mt-1' />
                  ) : (
                    <p className='type-caption font-normal text-center'>
                      {t(
                        'loyalty-page-new-version.check-in-loyalty-modal.member-since',
                        {
                          membershipDate: format(
                            membershipDate,
                            Formats.date.long_without_days
                          )
                        }
                      )}
                    </p>
                  )}
                </div>
              </div> */}
              <div className='flex flex-col justify-center items-center h-full'>
                {isLoading && (
                  <div className='flex flex-col justify-center items-center'>
                    <Skeleton className='w-32 h-32' />
                    <Skeleton className='w-4/5 h-3 mt-1' />
                  </div>
                )}
                {!isLoading && (
                  <div>
                    <QRCodeSVG value={membershipNumber} />
                    <p className='type-caption text-secondary text-center mt-1'>
                      {membershipNumber}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className='border-t bg-white'>
        <div className='m-4 mt-0 flex flex-col justify-center items-center'>
          <p className='type-subhead text-center mt-3 mb-3'>
            {t('loyalty-page-new-version.check-in-loyalty-modal.or')}
          </p>
          {isLoading ? (
            <Skeleton className='w-4/5 h-6 my-4' />
          ) : (
            <p className='type-subhead text-center mb-3'>
              {t(
                'loyalty-page-new-version.check-in-loyalty-modal.log-in-description',
                { emailOrPhoneNumber }
              )}
            </p>
          )}
          <div className='flex flex-col md:flex-row justify-center gap-2'>
            <Button variant='secondary' size='sm' iconRight={<LaunchIcon />}>
              {t(
                'loyalty-page-new-version.check-in-loyalty-modal.order-online'
              )}
            </Button>
            <Button variant='secondary' size='sm' iconRight={<LaunchIcon />}>
              {t(
                'loyalty-page-new-version.check-in-loyalty-modal.order-on-app'
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
