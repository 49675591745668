import { IFormValues } from './CompleteProfileModal'
import { IGuestData } from '@local/common/src/network'
import { t } from '@local/translations'
import * as Yup from 'yup'
import * as React from 'react'

const parseFormValuesToGuestData = ({
  firstName,
  lastName,
  birthDay,
  birthMonth,
  email,
  phoneNumber,
  subscribeToMarketing
}: IFormValues): IGuestData => {
  return {
    firstName: firstName || '',
    lastName: lastName || '',
    birthdayMonth: birthMonth || '',
    birthdayDay: birthDay || '',
    email: email || '',
    phoneNumber:
      (phoneNumber?.countryCode || '') + (phoneNumber?.nationalNumber || ''),
    subscribeToMarketing: subscribeToMarketing || false,
    restaurantGuid: '',
    secureToken: ''
  }
}

const prepareGuestData = (
  data: IGuestData,
  restaurantGuid: string,
  secureToken: string
): IGuestData => {
  const preparedData: { [key: string]: any } = {}

  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      preparedData[key] = value
    }
  })

  return {
    ...preparedData,
    restaurantGuid,
    secureToken
  }
}

export const useCompleteProfileModal = (guestAccountData: any) => {
  const {
    hasSignedUpForBirthday,
    hasFirstName,
    hasLastName,
    hasEmail,
    hasPhone
  } = guestAccountData?.data?.account || {}

  const initialValues = {
    ...(!hasFirstName && { firstName: '' }),
    ...(!hasLastName && { lastName: '' }),
    ...(!hasSignedUpForBirthday && { birthMonth: '' }),
    ...(!hasSignedUpForBirthday && { birthDay: '' }),
    ...(!hasEmail && { email: '' }),
    ...(!hasPhone && {
      phoneNumber: { countryCode: '', countryIsoCode: '', nationalNumber: '' }
    }),
    subscribeToMarketing: false
  }

  const ValidationSchema = Yup.object().shape({
    ...(!hasFirstName && {
      firstName: Yup.string().required(
        t('loyalty-page-new-version.complete-profile-modal.first-name.required')
      )
    }),
    ...(!hasLastName && {
      lastName: Yup.string().required(
        t('loyalty-page-new-version.complete-profile-modal.last-name.required')
      )
    }),
    ...(!hasSignedUpForBirthday && {
      birthDay: Yup.string().required(
        t('loyalty-page-new-version.complete-profile-modal.birth-day.required')
      )
    }),
    ...(!hasSignedUpForBirthday && {
      birthMonth: Yup.string().required(
        t(
          'loyalty-page-new-version.complete-profile-modal.birth-month.required'
        )
      )
    }),
    ...(!hasEmail && {
      email: Yup.string()
        .email(
          t(
            'loyalty-page-new-version.complete-profile-modal.email-address.invalid'
          )
        )
        .required(
          t(
            'loyalty-page-new-version.complete-profile-modal.email-address.required'
          )
        )
    }),
    ...(!hasPhone && {
      phoneNumber: Yup.object().shape({
        countryCode: Yup.string().optional(),
        nationalNumber: Yup.string()
          .required(
            t(
              'loyalty-page-new-version.complete-profile-modal.phone-number.required'
            )
          )
          .min(
            3,
            t(
              'loyalty-page-new-version.complete-profile-modal.phone-number.min'
            )
          )
          .max(
            10,
            t(
              'loyalty-page-new-version.complete-profile-modal.phone-number.max'
            )
          )
      })
    })
  })

  const monthsList = [
    {
      label: t(
        'loyalty-page-new-version.complete-profile-modal.months.january'
      ),
      value: '1'
    },
    {
      label: t(
        'loyalty-page-new-version.complete-profile-modal.months.february'
      ),
      value: '2'
    },
    {
      label: t('loyalty-page-new-version.complete-profile-modal.months.march'),
      value: '3'
    },
    {
      label: t('loyalty-page-new-version.complete-profile-modal.months.april'),
      value: '4'
    },
    {
      label: t('loyalty-page-new-version.complete-profile-modal.months.may'),
      value: '5'
    },
    {
      label: t('loyalty-page-new-version.complete-profile-modal.months.june'),
      value: '6'
    },
    {
      label: t('loyalty-page-new-version.complete-profile-modal.months.july'),
      value: '7'
    },
    {
      label: t('loyalty-page-new-version.complete-profile-modal.months.august'),
      value: '8'
    },
    {
      label: t(
        'loyalty-page-new-version.complete-profile-modal.months.september'
      ),
      value: '9'
    },
    {
      label: t(
        'loyalty-page-new-version.complete-profile-modal.months.october'
      ),
      value: '10'
    },
    {
      label: t(
        'loyalty-page-new-version.complete-profile-modal.months.november'
      ),
      value: '11'
    },
    {
      label: t(
        'loyalty-page-new-version.complete-profile-modal.months.december'
      ),
      value: '12'
    }
  ]

  const [
    hasDismissedCompleteProfileModal,
    setHasDismissedCompleteProfileModal
  ] = React.useState(
    window.localStorage.getItem('hasDismissedCompleteProfileModal') === 'true'
  )

  React.useEffect(() => {
    window.localStorage.setItem(
      'hasDismissedCompleteProfileModal',
      String(hasDismissedCompleteProfileModal)
    )
  }, [hasDismissedCompleteProfileModal])

  const getDayslist = (month: string) => {
    const daysInMonth = new Date(2020, parseInt(month), 0).getDate()
    return Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString())
  }

  return {
    initialValues,
    ValidationSchema,
    monthsList,
    hasDismissedCompleteProfileModal,
    getDayslist,
    parseFormValuesToGuestData,
    prepareGuestData,
    setHasDismissedCompleteProfileModal
  }
}
