import * as React from 'react'
import cx from 'classnames'

export interface DefaultLogoImageProps {
  className?: string
}

export const DefaultLogoImage = ({
  className = 'w-16 h-16'
}: DefaultLogoImageProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox={`0 0 72 72`}
      fill='none'
      className={cx('inline-block align-middle', className)}
      preserveAspectRatio='xMidYMid meet'
    >
      <g clipPath='url(#a)' viewBox='0 0 48 48'>
        <path fill='#74522B' d='M72 0v72H0V0z' />
        <path fill='#D5C7B8' d='M10.657 34.9h50.795v36.815H10.657z' />
        <path fill='#9D8161' d='M16.297 22h-5.64v13.17h5.64V22Z' />
        <path fill='#F1E8E1' d='M21.936 22h-5.64v13.17h5.64V22Z' />
        <path fill='#9D8161' d='M27.575 22h-5.64v13.17h5.64V22Z' />
        <path fill='#F1E8E1' d='M33.218 22h-5.64v13.17h5.64V22Z' />
        <path fill='#9D8161' d='M38.856 22h-5.64v13.17h5.64V22Z' />
        <path
          fill='#F1E8E1'
          d='M44.497 22h-5.64v13.17h5.64V22ZM55.777 22h-5.64v13.17h5.64V22Z'
        />
        <path
          fill='#9D8161'
          d='M50.136 22h-5.639v13.17h5.64V22ZM61.46 22h-5.705v13.17h5.705V22Z'
        />
        <path
          fill='#74522B'
          d='M36.762 51.495a5.985 5.985 0 0 1 5.982 5.983V76.97H30.778V57.478a5.985 5.985 0 0 1 5.982-5.983h.002Z'
        />
        <path fill='#F8F5F2' d='M37.049 51.495h-.575v25.592h.575V51.495Z' />
        <path fill='#FFFAF8' d='M30.186 42.785h13.149v5.09H30.186z' />
        <path
          fill='#F8F5F2'
          d='M38.86 35.169a2.82 2.82 0 0 1-5.64 0h5.642-.001ZM27.585 35.169a2.82 2.82 0 0 1-5.64 0h5.642-.002ZM16.298 35.169a2.82 2.82 0 0 1-5.64 0H16.3h-.002ZM50.132 35.169a2.818 2.818 0 0 1-2.816 2.82 2.82 2.82 0 0 1-2.817-2.82h5.635-.002ZM61.394 35.169a2.818 2.818 0 0 1-2.817 2.82 2.82 2.82 0 0 1-2.816-2.82h5.634-.001Z'
        />
        <path
          fill='#D5C7B8'
          d='M55.765 35.169a2.818 2.818 0 0 1-2.816 2.82 2.82 2.82 0 0 1-2.817-2.82h5.635-.002ZM44.498 35.169a2.818 2.818 0 0 1-2.816 2.82 2.82 2.82 0 0 1-2.817-2.82H44.5h-.002ZM33.217 35.169a2.818 2.818 0 0 1-2.816 2.82 2.82 2.82 0 0 1-2.817-2.82h5.635-.002ZM21.949 35.169a2.818 2.818 0 0 1-2.817 2.82 2.82 2.82 0 0 1-2.817-2.82h5.635-.001Z'
        />
        <path
          fill='#FFFAF8'
          d='M52.279 42.124a4.979 4.979 0 0 1 4.976 4.977v8.278h-9.954v-8.278a4.979 4.979 0 0 1 4.976-4.977h.002ZM21.037 42.124a4.979 4.979 0 0 1 4.976 4.977v8.278H16.06v-8.278a4.979 4.979 0 0 1 4.976-4.977h.002Z'
        />
        <path
          fill='#74522B'
          d='M57.418 57.197H47.139l-.925-1.818h12.13l-.926 1.818ZM26.176 57.197H15.897l-.925-1.818H27.1l-.925 1.818Z'
        />
        <path
          stroke='#74522B'
          strokeMiterlimit='10'
          strokeWidth='.527'
          d='M47.303 49.701h9.953M16.06 49.701h9.954'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M72 0v72H0V0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
