import * as React from 'react'

export interface CloseIconProps {
  testId?: string | number
  className?: string
}

export const CloseIcon = ({ className, testId }: CloseIconProps) => {
  return (
    <i className={className} data-testid={testId}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        fill='none'
        aria-label='Close'
      >
        <path
          fill='#252525'
          fillRule='evenodd'
          d='M7.008 7.008a1 1 0 0 1 1.414 0L12 10.586l3.578-3.578a1 1 0 1 1 1.414 1.414L13.414 12l3.578 3.578a1 1 0 0 1-1.414 1.414L12 13.414l-3.578 3.578a1 1 0 0 1-1.414-1.414L10.586 12 7.008 8.422a1 1 0 0 1 0-1.414Z'
          clipRule='evenodd'
        />
      </svg>
    </i>
  )
}
