import * as React from 'react'
import cx from 'classnames'
import { useModal } from '@local/consumer-ui-modal'
import { CloseIcon } from '@local/assets'
import { IconButton } from '@toasttab/buffet-pui-buttons'

interface InternalHeaderProps {
  testId?: string
}
export const InternalHeader = ({ testId }: InternalHeaderProps) => {
  const { onCloseRequest } = useModal()

  return (
    <div className='flex flex-row justify-end'>
      <IconButton
        className={cx(
          'filter rounded-full text-default h-8 w-8 justify-center flex flex-col items-center',
          'bg-secondary'
        )}
        icon={<CloseIcon className='' />}
        onClick={onCloseRequest}
        aria-label='Close'
        testId={testId + '-close-button-slider'}
      />
    </div>
  )
}
