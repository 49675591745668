import * as React from 'react'
import cx from 'classnames'
import { Image } from '@toasttab/buffet-pui-image'
import defaultLoyaltyBackground from './assets/default-loyalty-background.png'
import defaultLoyaltyLogo from './assets/default-loyalty-logo.png'
import { ProgramDescription } from './ProgramDescription'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export interface HeaderProps {
  programName: string
  programDescription: string
  logoUrl: string
  backgroundUrl: string
  testId?: string | number
  isLoading?: boolean
  viewStoreInfoCallback?: () => void
}

/**
 * Header component
 */
export const Header = ({
  testId = 'loyalty-account-header',
  programName,
  programDescription,
  isLoading,
  logoUrl,
  backgroundUrl
}: HeaderProps) => {
  const logoClassName = cx(
    'rounded-full w-[72px] h-[72px] border-2 border-white'
  )

  return (
    <div
      data-testid={testId}
      className='flex flex-col items-center justify-start overflow-hidden'
    >
      <div
        className={cx(
          'z-0 absolute overflow-hidden bg-no-repeat bg-bottom bg-cover md:bg-contain',
          'w-full max-w-4xl h-[150px] md:h-[400px] md:-mt-[250px]',
          'md:rounded-br-[100%] md:rounded-bl-[100%]',
          'rounded-br-[50%] rounded-bl-[50%]'
        )}
        style={{
          backgroundImage: `url(${backgroundUrl}), url(${defaultLoyaltyBackground})`
        }}
      ></div>
      <div className='relative flex flex-row justify-center mt-28'>
        <Image
          testId={`${testId}-logo`}
          src={logoUrl}
          alt='logo'
          showError={true}
          errorComp={
            <Image
              testId={`${testId}-logo-fallback`}
              src={defaultLoyaltyLogo}
              alt='logo'
              containerClassName={logoClassName}
              fit='cover'
            />
          }
          containerClassName={logoClassName}
          fit='cover'
          position='center'
        />
      </div>
      {isLoading && (
        <div className='flex flex-col justify-center items-center mt-4 w-[500px]'>
          <Skeleton className='w-full h-8 mb-2' />
          <Skeleton className='w-96 h-6' />
        </div>
      )}
      {!isLoading && (
        <div className='flex flex-col justify-center items-center mt-4'>
          <h1 className='text-center type-headline-4 font-effra font-normal'>
            {programName}
          </h1>
          {programDescription && (
            <ProgramDescription programDescription={programDescription} />
          )}
          {/* <Button variant='text-link' onClick={viewStoreInfoCallback}>
          {t('loyalty-page-new-version.header.view-store-info-button')}
        </Button> */}
          {/* Commented content until location selector modal be ready to go. */}
        </div>
      )}
    </div>
  )
}
