import * as React from 'react'
import { Modal } from '@local/consumer-ui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { t } from '@local/translations'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'

interface ConfirmationModalProps {
  testId?: string
  isOpen: boolean
  lastFour: string
  cardName: string
  isDeletingLoading: boolean
  onDismiss: () => void
  onConfirm: () => void
}
export const ConfirmationModal = ({
  testId,
  isOpen,
  lastFour,
  cardName,
  isDeletingLoading,
  onDismiss,
  onConfirm
}: ConfirmationModalProps) => {
  const formattedCardName = React.useMemo(() => {
    switch (cardName) {
      case 'VISA':
        return t('loyalty-page-new-version.linked-card-item.visa')
      case 'MASTERCARD':
        return t('loyalty-page-new-version.linked-card-item.mastercard')
      case 'GOOGLEPAY':
        return t('loyalty-page-new-version.linked-card-item.googlepay')
      case 'AMEX':
        return t('loyalty-page-new-version.linked-card-item.amex')
      case 'DISCOVER':
        return t('loyalty-page-new-version.linked-card-item.discover')
      case 'C2P':
        return t('loyalty-page-new-version.linked-card-item.c2p')
      case 'APPLEPAY':
        return t('loyalty-page-new-version.linked-card-item.applepay')
      case 'GIFTCARD':
        return t('loyalty-page-new-version.linked-card-item.giftcard')
      case 'TOASTCASH':
        return t('loyalty-page-new-version.linked-card-item.toastcash')
      default:
        return t('loyalty-page-new-version.linked-card-item.generic')
    }
  }, [cardName])

  return (
    <Modal
      showHeaderBackground={false}
      showCloseButton={false}
      isOpen={isOpen}
      testId={testId}
      contentClassName='max-h-[311px] w-[280px] md:w-[320px]'
    >
      <Modal.Header>
        <h4 className='type-headline-4 font-medium text-center font-effra mt-4 text-default'>
          {t(
            'loyalty-page-new-version.linked-cards-management-modal.confirmation.title'
          )}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center type-default text-default'>
          {t(
            'loyalty-page-new-version.linked-cards-management-modal.confirmation.message',
            { lastFour, cardName: formattedCardName }
          )}
        </p>
        <div className='flex flex-col justify-stretch gap-3 mt-6'>
          <Button
            onClick={onConfirm}
            size='lg'
            testId={testId + '-confirm-button'}
            disabled={isDeletingLoading}
            iconLeft={
              isDeletingLoading && (
                <AutorenewIcon size='sm' className='animate-spin' />
              )
            }
          >
            {t(
              'loyalty-page-new-version.linked-cards-management-modal.confirmation.confirm-button'
            )}
          </Button>
          <Button
            variant='secondary'
            size='lg'
            onClick={onDismiss}
            testId={testId + '-cancel-button'}
            disabled={isDeletingLoading}
          >
            {t(
              'loyalty-page-new-version.linked-cards-management-modal.confirmation.cancel-button'
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
