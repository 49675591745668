/**
 * @file stringsHelper.ts
 * @description This file contains utility functions for handling and manipulating strings.
 * These helper functions are used throughout the application to format, transform, and
 * generate string values based on various inputs and conditions.
 */

type OrderSource =
  | 'ONLINE_ORDERING'
  | 'TOAST_APP'
  | 'KIOSK'
  | 'POS_TOAST_GO'
  | 'ANY'

type BehaviourMode = 'TAKE_OUT' | 'DINE_IN' | 'DELIVERY' | 'ANY'

interface BehaviourModeOption {
  value: BehaviourMode
  label: string
  disabled: boolean
}

interface OrderSourceOption {
  value: OrderSource
  label: string
  disabled: boolean
}

const BEHAVIOUR_OPTIONS: BehaviourModeOption[] = [
  {
    value: 'TAKE_OUT',
    label: 'Take out',
    disabled: false
  },
  {
    value: 'DINE_IN',
    label: 'Dine in',
    disabled: false
  },
  {
    value: 'DELIVERY',
    label: 'Delivery',
    disabled: false
  },
  {
    value: 'ANY',
    label: 'Any',
    disabled: false
  }
]

export const ORDER_SOURCE_OPTIONS: OrderSourceOption[] = [
  {
    value: 'ONLINE_ORDERING',
    label: 'Online ordering website',
    disabled: false
  },
  {
    value: 'TOAST_APP',
    label: 'Local by Toast app',
    disabled: false
  },
  {
    value: 'KIOSK',
    label: 'Kiosk',
    disabled: false
  },
  {
    value: 'POS_TOAST_GO',
    label: 'POS/Toast Go',
    disabled: false
  },
  {
    value: 'ANY',
    label: 'Any',
    disabled: false
  }
]

export const getGuestDiningOptionBonusDescription = (
  orderSource: OrderSource,
  behaviourMode: BehaviourMode,
  scheduled: boolean
) => {
  const orderSourceOption = ORDER_SOURCE_OPTIONS.find(
    (o) => o.value === orderSource
  )
  const orderSourceLabel = orderSourceOption ? orderSourceOption.label : ''

  const behaviourOption = BEHAVIOUR_OPTIONS.find(
    (o) => o.value === behaviourMode
  )
  const behaviourLabel = behaviourOption ? behaviourOption.label : ''

  const orderSourceFormat =
    orderSource === 'POS_TOAST_GO' || orderSource === 'TOAST_APP'
      ? orderSourceLabel
      : orderSourceLabel.toLowerCase()

  return `for ${scheduled ? 'scheduled ' : ''}${
    behaviourMode !== 'ANY' ? behaviourLabel.toLowerCase() + ' ' : ''
  }orders${orderSource !== 'ANY' ? ' from the ' + orderSourceFormat : ''}`
}

export const uniqueKey = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const array = new Uint8Array(8)
  window.crypto.getRandomValues(array)
  return Array.from(array, (byte) => chars[byte % chars.length]).join('')
}

export const isValidUrl = (website: string): boolean => {
  try {
    const url = new URL(website)
    return url.protocol === 'http:' || url.protocol === 'https:'
  } catch (error) {
    return false
  }
}
