import * as React from 'react'
import { Trans } from '@local/translations'
import { PowerByToastIcon } from '@local/assets'

export interface FooterProps {
  testId?: string | number
}

/**
 * Footer component
 */
export const Footer = ({ testId = 'footer' }: FooterProps) => {
  return (
    <div
      data-testid={testId}
      className='flex flex-col justify-center items-center'
    >
      <p className='text-default type-caption text-center'>
        <Trans
          i18nKey='loyalty-page-new-version.footer.description'
          components={[
            <a
              href='#'
              className='font-semibold underline visited:text-link-visited'
              key={'link1'}
            />,
            <a
              href='#'
              className='font-semibold underline visited:text-link-visited'
              key={'link2'}
            />
          ]}
        />
      </p>
      <PowerByToastIcon
        testId={testId + '-power-by-toast-icon'}
        className='w-36 md:w-40 mt-6'
      />
    </div>
  )
}
