import * as React from 'react'
import { t } from '@local/translations'
import { Button } from '@toasttab/buffet-pui-buttons'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import { Image } from '@toasttab/buffet-pui-image'
import { formatCurrency, Currency } from '@toasttab/buffet-pui-number-utilities'
import { DefaultLogoImage } from '../Assets'

export interface GiftCardProps {
  testId?: string | number
  balance: number
  logoUrl: string
  restaurantName: string
  restaurantDescription: string
  addBalanceCallback?: () => void
}

/**
 * GiftCard component
 */
export const GiftCard = ({
  testId = 'loyalty-account-gift-card',
  balance,
  logoUrl,
  restaurantName,
  restaurantDescription,
  addBalanceCallback
}: GiftCardProps) => {
  return (
    <div data-testid={testId}>
      <div className='flex flex-row justify-between items-center -mb-1'>
        <div>
          <h3 className='type-headline-5 font-effra font-medium'>
            {t('loyalty-page-new-version.gift-card.title')}
          </h3>
        </div>
        <div>
          <Button
            variant='text-link'
            iconRight={<LaunchIcon size='xs' />}
            size='sm'
            onClick={addBalanceCallback}
          >
            {t('loyalty-page-new-version.gift-card.add-balance')}
          </Button>
        </div>
      </div>
      <div>
        <div className='flex flex-col items-center my-4'>
          <div className='relative flex flex-col items-center min-h-[190px] min-w-[310px] md:w-full md:min-h-56 shadow-xl rounded-lg bg-white overflow-hidden mt-2'>
            <div className='flex flex-row items-center absolute z-5 mt-4 w-full px-4'>
              <div className='min-w-12 max-w-14'>
                <Image
                  src={logoUrl}
                  alt={restaurantName}
                  className='h-12 w-12'
                  fit='contain'
                  showError
                  errorComp={
                    <DefaultLogoImage className='w-14 h-14 rounded-full' />
                  }
                />
              </div>
              <div className='ml-4 max-w-36'>
                <p className='type-default font-semibold line-clamp-2'>
                  {restaurantName}
                </p>
                <p className='type-caption text-secondary line-clamp-2'>
                  {restaurantDescription}
                  {/* TODO: Confirm with Evan what should be displayed here */}
                </p>
              </div>
              <div className='ml-auto type-large font-semibold'>
                {formatCurrency(
                  {
                    amount: balance,
                    currency: Currency.USD
                  },
                  'es-US',
                  {}
                )}
              </div>
            </div>
            <div
              className='absolute bottom-0 w-full min-h-32 flex flex-col justify-end'
              data-testid={testId + '-gift-card-image-box'}
            >
              <Image
                src='https://github.toasttab.com/toasttab/shared-static-assets/blob/main/images/gift-cards/gift-card-graphic.png?raw=true'
                alt={t(
                  'loyalty-page-new-version.gift-card.gift-card-image-alt'
                )}
                fit='contain'
                containerClassName='w-full'
                testId={testId + '-gift-card-image'}
                errorComp={<div className='w-full'>test</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
