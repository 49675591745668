import { LoyaltyAccountPageResponse } from '@local/loyalty-page-new-version/src/GuestAccountProvider/IGuestAccountData'
import { AxiosResponse } from 'axios'

export const getGuestAccountDataRepository = async (
  params: any
): Promise<AxiosResponse<LoyaltyAccountPageResponse, any>> => {
  const {
    meta: { instance },
    queryKey: [, secureToken]
  } = params
  return instance.get(`/guest/account/${secureToken}`)
}
