import * as React from 'react'

export interface CardGenericIconProps {
  testId?: string | number
  className?: string
}

export const CardGenericIcon = ({
  testId,
  className
}: CardGenericIconProps) => {
  return (
    <i data-testid={testId}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 40 40'
        fill='none'
        className={className}
        aria-label='Generic Card'
      >
        <rect
          width='40'
          height='25'
          y='7.5'
          fill='#000'
          fill-opacity='.28'
          rx='2'
        />
        <rect
          width='7.5'
          height='5'
          x='28.125'
          y='11.875'
          fill='url(#a)'
          rx='.625'
        />
        <rect
          width='9.167'
          height='2.5'
          x='4.375'
          y='25.625'
          fill='url(#b)'
          rx='.625'
        />
        <rect
          width='9.167'
          height='2.5'
          x='15.417'
          y='25.625'
          fill='url(#c)'
          rx='.625'
        />
        <rect
          width='9.167'
          height='2.5'
          x='26.458'
          y='25.625'
          fill='url(#d)'
          rx='.625'
        />
        <defs>
          <linearGradient
            id='a'
            x1='35.625'
            x2='28.125'
            y1='12.5'
            y2='16.875'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#D9D9D9' />
            <stop offset='1' stopColor='#fff' />
          </linearGradient>
          <linearGradient
            id='b'
            x1='13.542'
            x2='9.491'
            y1='25.938'
            y2='31.713'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#D9D9D9' />
            <stop offset='1' stopColor='#fff' />
          </linearGradient>
          <linearGradient
            id='c'
            x1='24.584'
            x2='20.533'
            y1='25.938'
            y2='31.713'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#D9D9D9' />
            <stop offset='1' stopColor='#fff' />
          </linearGradient>
          <linearGradient
            id='d'
            x1='35.625'
            x2='31.574'
            y1='25.938'
            y2='31.713'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#D9D9D9' />
            <stop offset='1' stopColor='#fff' />
          </linearGradient>
        </defs>
      </svg>
    </i>
  )
}
