import * as React from 'react'
import { Modal } from '@local/consumer-ui-modal'
import { CakeIcon, PeopleIcon } from '@local/assets'
import { t, Trans } from '@local/translations'
import { Formik } from 'formik'
import {
  TextInputField,
  SelectField,
  PhoneInputField,
  CheckboxField
} from '@toasttab/buffet-pui-forms'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useGuestAccount } from '../GuestAccountProvider'
import {
  IGuestData,
  useCompleteProfileService
} from '@local/common/src/network'
import { useCompleteProfileModal } from './useCompleteProfileModal'
import { triggerAlert } from '../Alert'
import { useSentry } from 'banquet-runtime-modules'
import { ErrorState } from '../ErrorState'

export interface CompleteProfileModalProps {
  testId?: string
  isOpen: boolean
  onCloseRequest: () => undefined
  isBirthdayTreatAvailable: boolean
  rxName: string
}

export interface IFormValues {
  firstName?: string
  lastName?: string
  birthMonth?: string
  birthDay?: string
  email?: string
  phoneNumber?: {
    countryCode: string
    countryIsoCode: string
    nationalNumber: string
  }
  subscribeToMarketing?: boolean
}

/**
 * CompleteProfileModal component
 */
export const CompleteProfileModal = ({
  testId = 'complete-profile-modal',
  ...args
}: CompleteProfileModalProps) => {
  const sentry = useSentry()

  const { guestAccountData } = useGuestAccount() || {}
  const {
    parseFormValuesToGuestData,
    prepareGuestData,
    getDayslist,
    monthsList,
    ValidationSchema,
    initialValues
  } = useCompleteProfileModal(guestAccountData)

  const {
    hasSignedUpForBirthday,
    hasFirstName,
    hasLastName,
    hasEmail,
    hasPhone
  } = guestAccountData?.data?.account || {}

  const {
    mutate: completeProfileMutation,
    isPending,
    isError
  } = useCompleteProfileService()

  const onCompleteProfileSuccess = () => {
    triggerAlert(t('loyalty-page-new-version.complete-profile-modal.success'), {
      type: 'success'
    })
    args.onCloseRequest()
  }

  const onCompleteProfileError = (error: any) => {
    triggerAlert(t('loyalty-page-new-version.complete-profile-modal.error'), {
      type: 'error'
    })
    sentry.captureException(error)
  }

  const onSubmitHandler = (formValues: IFormValues) => {
    let guestData: IGuestData = parseFormValuesToGuestData(formValues)
    guestData = prepareGuestData(
      guestData,
      guestAccountData?.data?.restaurantGuid || '',
      guestAccountData?.data?.account?.secureToken || ''
    )

    completeProfileMutation(guestData, {
      onSuccess: onCompleteProfileSuccess,
      onError: onCompleteProfileError
    })
  }

  const onTryAgain = () => {
    window.location.reload()
  }

  return (
    <Modal {...args} size='md' hasFooter>
      <Modal.Header
        title={t('loyalty-page-new-version.complete-profile-modal.title')}
      >
        <div className='flex flex-row justify-center'>
          {args.isBirthdayTreatAvailable ? (
            <CakeIcon className='w-40' testId={testId + '-cake-icon'} />
          ) : (
            <PeopleIcon className='w-28' testId={testId + '-people-icon'} />
          )}
        </div>
      </Modal.Header>
      {isError && <ErrorState callback={onTryAgain} />}
      {!isError && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <>
              <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                  <div className='pb-6 md:mb-6 md:pb-24'>
                    <h1 className='type-headline-4 font-effra text-center font-medium text-default'>
                      {t(
                        'loyalty-page-new-version.complete-profile-modal.title'
                      )}
                    </h1>
                    <p className='type-default text-default text-center'>
                      {args.isBirthdayTreatAvailable
                        ? t(
                            'loyalty-page-new-version.complete-profile-modal.birthday-sub-title'
                          )
                        : t(
                            'loyalty-page-new-version.complete-profile-modal.non-birthday-sub-title',
                            { rxName: args.rxName }
                          )}
                    </p>
                    <div
                      data-testid={testId + '-complet-profile-form'}
                      className='mt-6'
                    >
                      <p className='type-subhead text-secondary text-center'>
                        {t(
                          'loyalty-page-new-version.complete-profile-modal.all-fields-required'
                        )}
                      </p>
                      <div className='grid grid-flow-row grid-cols-1 xs:grid-cols-2 gap-3 mt-3'>
                        {!hasFirstName && (
                          <TextInputField
                            name='firstName'
                            label={t(
                              'loyalty-page-new-version.complete-profile-modal.first-name'
                            )}
                            required
                            testId={testId + '-first-name'}
                          />
                        )}
                        {!hasLastName && (
                          <TextInputField
                            name='lastName'
                            label={t(
                              'loyalty-page-new-version.complete-profile-modal.last-name'
                            )}
                            required
                            testId={testId + '-last-name'}
                          />
                        )}
                      </div>
                      {!hasSignedUpForBirthday && (
                        <div className='grid grid-flow-row grid-cols-1 xs:grid-cols-2 gap-3 mt-3'>
                          <SelectField
                            name='birthMonth'
                            label={t(
                              'loyalty-page-new-version.complete-profile-modal.birth-month'
                            )}
                            options={monthsList}
                            required
                            testId={testId + '-birth-month'}
                          />
                          <SelectField
                            name='birthDay'
                            label={t(
                              'loyalty-page-new-version.complete-profile-modal.birth-day'
                            )}
                            options={getDayslist(
                              formik.values.birthMonth ?? ''
                            )}
                            disabled={!formik.values.birthMonth}
                            required
                            testId={testId + '-birth-day'}
                          />
                        </div>
                      )}
                      {!hasEmail && (
                        <div className='mt-3'>
                          <TextInputField
                            name='email'
                            label={t(
                              'loyalty-page-new-version.complete-profile-modal.email-address'
                            )}
                            required
                            testId={testId + '-email-address'}
                          />
                        </div>
                      )}
                      {!hasPhone && (
                        <div className='mt-3'>
                          <PhoneInputField
                            name='phoneNumber'
                            label={t(
                              'loyalty-page-new-version.complete-profile-modal.phone-number'
                            )}
                            required
                            testId={testId + '-phone-number'}
                          />
                        </div>
                      )}

                      {hasPhone && (
                        <div className='mt-6 bg-darken-4 p-3 rounded-[4px]'>
                          <CheckboxField
                            testId={testId + '-sms'}
                            name='subscribeToMarketing'
                            label={
                              <div>
                                <p className='type-subhead text-default font-semibold'>
                                  {t(
                                    'loyalty-page-new-version.complete-profile-modal.get-sms-title'
                                  )}
                                </p>
                                <p className='type-subhead text-secondary'>
                                  {t(
                                    'loyalty-page-new-version.complete-profile-modal.get-sms-description'
                                  )}
                                </p>
                              </div>
                            }
                          />
                        </div>
                      )}

                      <div className='mt-6'>
                        <p className='text-center type-subhead text-secondary'>
                          <Trans
                            i18nKey='loyalty-page-new-version.complete-profile-modal.legal-disclaimer'
                            components={[
                              <a href='#' className='text-link' key='link1' />,
                              <a href='#' className='text-link' key='link2' />
                            ]}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size='lg'
                    className='w-full'
                    onClick={formik.submitForm}
                    disabled={!formik.isValid || !formik.dirty || isPending}
                    testId={testId + '-submit-button'}
                  >
                    {t(
                      'loyalty-page-new-version.complete-profile-modal.submit'
                    )}
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      )}
    </Modal>
  )
}
