import * as React from 'react'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import {
  RestaurantIcon,
  CellphoneIcon,
  LaunchIcon
} from '@toasttab/buffet-pui-icons'
import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'
import { t } from '@local/translations'
import { LoyaltyAccountRestaurantInfo } from '@local/loyalty-page-new-version'

type EntryMode = 'phone' | 'email'

export interface HowItWorksProps {
  testId?: string | number
  entryMode: EntryMode
  locations: LoyaltyAccountRestaurantInfo[]
  isDigitalChannelSupported: boolean
  // seeOtherLocationsCallback: () => void
  seeQRCodeCallback: () => void
  manageLinkedCreditCardsCallback: () => void
  orderOnlineCallback: () => void
  orderOnAppCallback: () => void
}

/**
 * HowItWorks component
 */
export const HowItWorks = ({
  testId = 'how-it-works',
  entryMode,
  locations = [],
  isDigitalChannelSupported,
  seeQRCodeCallback,
  manageLinkedCreditCardsCallback,
  orderOnlineCallback,
  orderOnAppCallback
}: HowItWorksProps) => {
  const screenSize = useScreenSize()

  const entryModeDescription =
    entryMode === 'phone'
      ? t('loyalty-page-new-version.how-it-works.entry-mode.phone')
      : t('loyalty-page-new-version.how-it-works.entry-mode.email')

  return (
    <div className='bg-white p-6' data-testid={testId}>
      <h3 className='type-headline-5 font-effra font-medium'>
        {t('loyalty-page-new-version.how-it-works.title')}
      </h3>
      <p className='mb-4 mt-2 type-default'>
        {t('loyalty-page-new-version.how-it-works.link-your-account')}
      </p>

      {locations.length > 1 && (
        <div
          className='px-4 py-3 mb-4 bg-surface-secondary'
          data-testid='multi-location-description'
        >
          <p className='type-default'>
            {t(
              'loyalty-page-new-version.how-it-works.this-restaurant-share-loyalty',
              { count: locations.length - 1 }
            )}
          </p>
          {/* <Button
            variant='text-link'
            className='my-0 py-0'
            onClick={seeOtherLocationsCallback}
          >
            See other locations
          </Button> */}
          {/* Commented content until location selector modal be ready to go. */}
        </div>
      )}

      <div className='flex flex-row justify-start align-top'>
        <div className='mr-6'>
          <RestaurantIcon size='md' aria-label='At the store icon' />
        </div>
        <div>
          <div>
            <h5 className='type-overline'>
              {t('loyalty-page-new-version.how-it-works.at-the-store')}
            </h5>
            <h6 className='type-default font-semibold'>
              {t(
                'loyalty-page-new-version.how-it-works.check-with-entry-mode',
                { entryModeDescription }
              )}
            </h6>
            <p className='type-default text-secondary'>
              {t('loyalty-page-new-version.how-it-works.entry-or-qrcode', {
                entryModeDescription
              })}
            </p>
            <Button variant='text-link' onClick={seeQRCodeCallback}>
              {t('loyalty-page-new-version.how-it-works.show-qr-code')}
            </Button>
          </div>
          <div className='mt-3'>
            <h6 className='type-default font-semibold'>
              {t(
                'loyalty-page-new-version.how-it-works.checking-linked-credit-card'
              )}
            </h6>
            <p className='type-default text-secondary'>
              {t(
                'loyalty-page-new-version.how-it-works.checking-linked-credit-card-description'
              )}
            </p>
            <p>
              <Button
                variant='text-link'
                onClick={manageLinkedCreditCardsCallback}
              >
                {t(
                  'loyalty-page-new-version.how-it-works.manage-linked-credit-cards'
                )}
              </Button>
            </p>
          </div>
        </div>
      </div>
      {isDigitalChannelSupported && (
        <div className='flex flex-row justify-start align-top mt-4'>
          <div className='mr-6'>
            <CellphoneIcon size='md' aria-label='Online icon' />
          </div>
          <div className=''>
            <h5 className='type-overline'>
              {t('loyalty-page-new-version.how-it-works.online')}
            </h5>
            <h6 className='type-default font-semibold'>
              {t('loyalty-page-new-version.how-it-works.checkin-toast-account')}
            </h6>
            <p className='type-default text-secondary'>
              {t(
                'loyalty-page-new-version.how-it-works.checkin-toast-account-description',
                { entryModeDescription }
              )}
            </p>
            <ButtonGroup
              className='mt-2'
              direction={
                screenSize <= ScreenSize.XS ? 'vertical' : 'horizontal'
              }
            >
              <Button
                iconRight={<LaunchIcon aria-label='Launch icon' />}
                variant='secondary'
                onClick={orderOnlineCallback}
              >
                {t('loyalty-page-new-version.how-it-works.order-online-button')}
              </Button>
              <Button
                iconRight={<LaunchIcon aria-label='Launch icon' />}
                variant='secondary'
                onClick={orderOnAppCallback}
              >
                {t('loyalty-page-new-version.how-it-works.order-on-app-button')}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}
    </div>
  )
}
