import * as React from 'react'
import cx from 'classnames'
import {
  useFloating,
  PortalManagement,
  Overlay,
  useMergeRefs,
  useInteractions,
  useRole,
  useDismiss
} from '@toasttab/buffet-pui-floating-ui-base'
import { CloseIcon } from '@local/assets'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import './Modal.css'

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'full'

export interface ModalProps {
  children: React.ReactNode
  testId?: string
  isOpen?: boolean
  onCloseRequest?: () => undefined
  showCloseButton?: boolean
  showHeaderBackground?: boolean
  showFooterBackground?: boolean
  contentClassName?: string
  size?: ModalSize
  hasFooter?: boolean
}

/**
 * Modal component
 */
/**
 * Renders a modal overlay component.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} [props.testId='consumer-ui-modal-overlay'] - The test ID for the modal overlay.
 * @param {boolean} [props.isOpen=true] - Determines whether the modal is open or closed.
 * @param {ReactNode} props.children - The content to be rendered inside the modal.
 * @param {() => undefined} props.onCloseRequest - The callback function to be called when the modal is requested to be closed.
 * @returns {JSX.Element} The rendered modal component.
 */
/**
 * Modal component that displays a floating overlay with content.
 *
 * @param testId - The test ID for the modal overlay.
 * @param isOpen - Whether the modal is open or not.
 * @param children - The content to be displayed inside the modal.
 * @param showCloseButton - Whether to show the close button or not.
 * @param showHeaderBackground - Whether to show the header background or not.
 * @param showFooterBackground - Whether to show the footer background or not.
 * @param onCloseRequest - Callback function to be called when the modal is closed.
 * @param contentClassName - The class name for the content.
 * @param size - The size of the modal.
 * @param hasFooter - Whether the modal has a footer or not.
 * @returns The rendered modal component.
 */

const noExec = () => undefined

const getSizeClassName = (size: ModalSize) => {
  switch (size) {
    case 'xxl':
      return 'md:max-w-2xl'
    case 'xl':
      return 'md:max-w-xl'
    case 'lg':
      return 'md:max-w-lg'
    case 'sm':
      return 'md:max-w-sm'
    case 'md':
    default:
      return 'md:max-w-md'
  }
}

export const Modal = ({
  testId = 'consumer-ui-modal-overlay',
  isOpen = true,
  children,
  showCloseButton,
  showHeaderBackground,
  showFooterBackground,
  onCloseRequest,
  contentClassName,
  size = 'sm',
  hasFooter = false
}: ModalProps) => {
  const { context, refs } = useFloating({
    open: isOpen,
    onOpenChange: onCloseRequest
  })

  const divRef = React.useRef<HTMLDivElement>(null)

  const ref = useMergeRefs([divRef, refs.setFloating])

  const zIndexClassName = 'z-30'

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'click',
    escapeKey: true,
    outsidePress: (event) => {
      if (event.target !== refs.floating.current?.closest('#modal-overlay')) {
        return false
      }
      event.preventDefault()
      event.stopPropagation()
      return true
    }
  })

  const role = useRole(context)

  const { getFloatingProps } = useInteractions([dismiss, role])

  const contentScroller = React.useRef<HTMLDivElement>(null)

  const PERCENTAGE_OF_COVERAGE = 1.5
  const SLIDER_HEADER_MINIMUM_HEIGHT = 0
  const ANIMATION_TIME_IN_SECONDS = 1

  /**
   * Listens to the scroll event and updates the scroll position of the modal.
   */
  const scrollListner = () => {
    const headerContainerHight =
      document.getElementById('modal-header-content')?.offsetHeight ||
      SLIDER_HEADER_MINIMUM_HEIGHT

    if (contentScroller.current) {
      const { scrollTop } = contentScroller.current
      const scrollY = -Math.min(
        ANIMATION_TIME_IN_SECONDS,
        scrollTop / (headerContainerHight * PERCENTAGE_OF_COVERAGE)
      )
      document.body.style.setProperty('--scroll-y', String(scrollY))
    }
  }

  React.useEffect(() => {
    if (contentScroller.current) {
      contentScroller.current.addEventListener('scroll', scrollListner)
    }

    return () => {
      if (contentScroller.current) {
        contentScroller.current.removeEventListener('scroll', scrollListner)
      }
    }
  }, [contentScroller.current])

  return (
    <ModalProvider
      {...{
        testId,
        isOpen,
        onCloseRequest,
        showCloseButton,
        showFooterBackground,
        showHeaderBackground
      }}
    >
      {isOpen && (
        <PortalManagement
          context={context}
          isModal={true}
          zIndexClassName={zIndexClassName}
          disableInitialFocus={false}
        >
          <Overlay
            testId={testId + '-overlay'}
            onPointerEnterCapture={noExec}
            onPointerLeaveCapture={noExec}
            lockScroll
            id='modal-overlay'
            className={cx(
              'fixed top-0 right-0 left-0 bottom-0 bg-darken-30',
              zIndexClassName
            )}
          >
            <div
              ref={ref}
              aria-modal
              {...getFloatingProps()}
              data-testid={testId}
            >
              <div
                className={cx(
                  'md:pin-center',
                  'absolute bottom-0',
                  'bg-white shadow-2xl',
                  getSizeClassName(size),
                  'w-full h-[90vh] md:h-3/4',
                  'md:rounded-[16px] rounded-tl-[16px] rounded-tr-[16px]',
                  'border text-secondary',
                  'overflow-hidden',
                  'flex flex-col justify-start',
                  { 'pb-24 md:pb-0': hasFooter },
                  contentClassName
                )}
              >
                <div
                  ref={contentScroller}
                  className={cx('w-full h-full overflow-auto')}
                >
                  {children}
                </div>
              </div>
            </div>
          </Overlay>
        </PortalManagement>
      )}
    </ModalProvider>
  )
}

export interface ModalBodyProps {
  children: React.ReactNode
}

export const ModalBody = ({ children }: ModalBodyProps) => {
  return <div className='px-6 pt-4'>{children}</div>
}

export interface ModalHeaderProps {
  children?: React.ReactNode
  title?: string
}

export const ModalHeader = ({ children, title }: ModalHeaderProps) => {
  const { onCloseRequest, showCloseButton, showHeaderBackground, testId } =
    useModal()

  return (
    <>
      {showCloseButton && (
        <div
          className={cx(
            'sticky -mb-[65px] z-5 w-full flex-row flex justify-end py-4 pr-6 top-0 opacity-0',
            { 'bg-surface-secondary': showHeaderBackground },
            { 'bg-white': !showHeaderBackground }
          )}
          id='slider-header'
        >
          {title && (
            <div className='mr-4 ml-12 mt-1 w-full'>
              <h3 className='type-large font-semibold text-center line-clamp-1'>
                {title}
              </h3>
            </div>
          )}
          <div>
            <IconButton
              className={cx(
                'filter rounded-full text-default h-8 w-8 justify-center flex flex-col items-center',
                { 'bg-secondary': showHeaderBackground }
              )}
              icon={<CloseIcon className='' />}
              onClick={onCloseRequest}
              aria-label='Close'
              testId={testId + '-close-button-slider'}
            />
          </div>
        </div>
      )}
      <div
        data-testid={testId + '-header'}
        id='modal-header-content'
        className={cx('pt-4 px-6 bg-action relative z-0', {
          'bg-surface-secondary': showHeaderBackground
        })}
      >
        {showCloseButton && (
          <div className='w-full flex-row flex justify-end mb-2'>
            <IconButton
              className={cx(
                'filter rounded-full text-default h-8 w-8 justify-center flex flex-col items-center',
                { 'bg-secondary': showHeaderBackground }
              )}
              icon={<CloseIcon className='' />}
              onClick={onCloseRequest}
              aria-label='Close'
              testId={testId + '-close-button'}
            />
          </div>
        )}
        {children}
      </div>
    </>
  )
}

export interface ModalFooterProps {
  children: React.ReactNode
}

export const ModalFooter = ({ children }: ModalFooterProps) => {
  const { showFooterBackground, testId } = useModal()
  return (
    <div
      data-testid={testId + '-footer'}
      className={cx(
        'p-6',
        'w-full mt-auto fixed bottom-0',
        {
          'bg-surface-secondary': showFooterBackground
        },
        {
          'bg-white': !showFooterBackground
        }
      )}
    >
      {children}
    </div>
  )
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

const ModalContext = React.createContext({
  testId: 'consumer-ui-modal-overlay',
  isOpen: false,
  showHeaderBackground: true,
  showFooterBackground: true,
  showCloseButton: true,
  onCloseRequest: noExec
})

export interface ModalProviderProps {
  children: React.ReactNode
  testId?: string
  isOpen?: boolean
  showHeaderBackground?: boolean
  showFooterBackground?: boolean
  showCloseButton?: boolean
  onCloseRequest?: () => undefined
}

export const ModalProvider = ({
  children,
  testId = 'consumer-ui-modal-overlay',
  isOpen = false,
  showHeaderBackground = true,
  showFooterBackground = true,
  showCloseButton = true,
  onCloseRequest = noExec
}: ModalProviderProps) => {
  return (
    <ModalContext.Provider
      value={{
        testId,
        isOpen,
        showHeaderBackground,
        showFooterBackground,
        showCloseButton,
        onCloseRequest
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = React.useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
