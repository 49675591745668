import * as React from 'react'

export interface EmptyPlateIconProps {
  testId?: string | number
  className?: string
}

export const EmptyPlateIcon = ({ testId, className }: EmptyPlateIconProps) => {
  return (
    <svg
      data-testid={testId}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      viewBox='0 0 230 150'
      fill='none'
      aria-description='Empty plate icon'
      role='img'
    >
      <g opacity='0.6'>
        <path
          d='M229.322 136.149L221.241 0L0.00378285 13.1307L8.08435 149.279L229.322 136.149Z'
          fill='#FFE2DD'
        />
        <path
          d='M222.251 17.0201L205.231 18.0303L206.242 35.0503L223.262 34.0401L222.251 17.0201Z'
          fill='#FFC2AC'
        />
        <path
          d='M224.272 51.0563L207.252 52.0664L208.262 69.0864L225.282 68.0763L224.272 51.0563Z'
          fill='#FFC2AC'
        />
        <path
          d='M226.292 85.0924L209.271 86.1025L210.282 103.123L227.302 102.112L226.292 85.0924Z'
          fill='#FFC2AC'
        />
        <path
          d='M228.312 119.129L211.292 120.139L212.302 137.159L229.322 136.149L228.312 119.129Z'
          fill='#FFC2AC'
        />
        <path
          d='M204.221 1.01035L187.201 2.02051L188.211 19.0405L205.231 18.0304L204.221 1.01035Z'
          fill='#FFC2AC'
        />
        <path
          d='M206.242 35.0465L189.222 36.0566L190.232 53.0766L207.252 52.0665L206.242 35.0465Z'
          fill='#FFC2AC'
        />
        <path
          d='M208.262 69.0826L191.242 70.0928L192.252 87.1128L209.272 86.1026L208.262 69.0826Z'
          fill='#FFC2AC'
        />
        <path
          d='M210.282 103.123L193.262 104.133L194.272 121.153L211.292 120.143L210.282 103.123Z'
          fill='#FFC2AC'
        />
        <path
          d='M188.216 19.0406L171.196 20.0508L172.206 37.0708L189.226 36.0606L188.216 19.0406Z'
          fill='#FFC2AC'
        />
        <path
          d='M190.236 53.0758L173.216 54.0859L174.226 71.1059L191.246 70.0958L190.236 53.0758Z'
          fill='#FFC2AC'
        />
        <path
          d='M192.256 87.1129L175.236 88.123L176.246 105.143L193.266 104.133L192.256 87.1129Z'
          fill='#FFC2AC'
        />
        <path
          d='M194.276 121.149L177.256 122.159L178.266 139.179L195.286 138.169L194.276 121.149Z'
          fill='#FFC2AC'
        />
        <path
          d='M170.185 3.02988L153.165 4.04004L154.175 21.0601L171.195 20.0499L170.185 3.02988Z'
          fill='#FFC2AC'
        />
        <path
          d='M172.206 37.067L155.186 38.0771L156.196 55.0972L173.216 54.087L172.206 37.067Z'
          fill='#FFC2AC'
        />
        <path
          d='M174.225 71.1021L157.205 72.1123L158.215 89.1323L175.235 88.1222L174.225 71.1021Z'
          fill='#FFC2AC'
        />
        <path
          d='M176.246 105.143L159.226 106.153L160.236 123.173L177.256 122.163L176.246 105.143Z'
          fill='#FFC2AC'
        />
        <path
          d='M154.179 21.0602L137.159 22.0703L138.169 39.0903L155.189 38.0802L154.179 21.0602Z'
          fill='#FFC2AC'
        />
        <path
          d='M156.2 55.0963L139.18 56.1064L140.19 73.1264L157.21 72.1163L156.2 55.0963Z'
          fill='#FFC2AC'
        />
        <path
          d='M158.219 89.1324L141.199 90.1426L142.209 107.163L159.229 106.152L158.219 89.1324Z'
          fill='#FFC2AC'
        />
        <path
          d='M160.24 123.169L143.22 124.179L144.23 141.199L161.25 140.189L160.24 123.169Z'
          fill='#FFC2AC'
        />
        <path
          d='M136.149 5.05039L119.129 6.06055L120.139 23.0806L137.159 22.0704L136.149 5.05039Z'
          fill='#FFC2AC'
        />
        <path
          d='M138.168 39.0865L121.148 40.0967L122.159 57.1167L139.179 56.1065L138.168 39.0865Z'
          fill='#FFC2AC'
        />
        <path
          d='M140.189 73.1227L123.169 74.1328L124.179 91.1528L141.199 90.1427L140.189 73.1227Z'
          fill='#FFC2AC'
        />
        <path
          d='M142.209 107.163L125.189 108.173L126.2 125.193L143.22 124.183L142.209 107.163Z'
          fill='#FFC2AC'
        />
        <path
          d='M120.143 23.0807L103.123 24.0908L104.133 41.1108L121.153 40.1007L120.143 23.0807Z'
          fill='#FFC2AC'
        />
        <path
          d='M122.163 57.1168L105.143 58.127L106.153 75.147L123.173 74.1368L122.163 57.1168Z'
          fill='#FFC2AC'
        />
        <path
          d='M124.183 91.1529L107.163 92.1631L108.173 109.183L125.193 108.173L124.183 91.1529Z'
          fill='#FFC2AC'
        />
        <path
          d='M126.204 125.189L109.184 126.199L110.194 143.219L127.214 142.209L126.204 125.189Z'
          fill='#FFC2AC'
        />
        <path
          d='M102.113 7.06992L85.0928 8.08008L86.1029 25.1001L103.123 24.0899L102.113 7.06992Z'
          fill='#FFC2AC'
        />
        <path
          d='M104.133 41.107L87.1133 42.1172L88.1234 59.1372L105.143 58.127L104.133 41.107Z'
          fill='#FFC2AC'
        />
        <path
          d='M106.154 75.1432L89.1338 76.1533L90.1439 93.1733L107.164 92.1632L106.154 75.1432Z'
          fill='#FFC2AC'
        />
        <path
          d='M108.173 109.183L91.1533 110.193L92.1635 127.213L109.183 126.203L108.173 109.183Z'
          fill='#FFC2AC'
        />
        <path
          d='M86.103 25.1002L69.083 26.1104L70.0932 43.1304L87.1132 42.1202L86.103 25.1002Z'
          fill='#FFC2AC'
        />
        <path
          d='M88.1226 59.1373L71.1025 60.1475L72.1127 77.1675L89.1327 76.1573L88.1226 59.1373Z'
          fill='#FFC2AC'
        />
        <path
          d='M90.1431 93.1725L73.123 94.1826L74.1332 111.203L91.1532 110.192L90.1431 93.1725Z'
          fill='#FFC2AC'
        />
        <path
          d='M92.1636 127.21L75.1436 128.22L76.1537 145.24L93.1737 144.23L92.1636 127.21Z'
          fill='#FFC2AC'
        />
        <path
          d='M68.0766 9.09043L51.0566 10.1006L52.0668 27.1206L69.0868 26.1104L68.0766 9.09043Z'
          fill='#FFC2AC'
        />
        <path
          d='M70.0972 43.1266L53.0771 44.1367L54.0873 61.1567L71.1073 60.1466L70.0972 43.1266Z'
          fill='#FFC2AC'
        />
        <path
          d='M72.1167 77.1627L55.0967 78.1729L56.1068 95.1929L73.1268 94.1827L72.1167 77.1627Z'
          fill='#FFC2AC'
        />
        <path
          d='M74.1372 111.203L57.1172 112.213L58.1273 129.233L75.1474 128.223L74.1372 111.203Z'
          fill='#FFC2AC'
        />
        <path
          d='M52.0669 27.1207L35.0469 28.1309L36.057 45.1509L53.077 44.1407L52.0669 27.1207Z'
          fill='#FFC2AC'
        />
        <path
          d='M54.0864 61.1568L37.0664 62.167L38.0766 79.187L55.0966 78.1768L54.0864 61.1568Z'
          fill='#FFC2AC'
        />
        <path
          d='M56.1069 95.193L39.0869 96.2031L40.0971 113.223L57.1171 112.213L56.1069 95.193Z'
          fill='#FFC2AC'
        />
        <path
          d='M58.1265 129.229L41.1064 130.239L42.1166 147.259L59.1366 146.249L58.1265 129.229Z'
          fill='#FFC2AC'
        />
        <path
          d='M34.0405 11.11L17.0205 12.1201L18.0307 29.1401L35.0507 28.13L34.0405 11.11Z'
          fill='#FFC2AC'
        />
        <path
          d='M36.06 45.1471L19.04 46.1572L20.0502 63.1772L37.0702 62.1671L36.06 45.1471Z'
          fill='#FFC2AC'
        />
        <path
          d='M38.0806 79.1832L21.0605 80.1934L22.0707 97.2134L39.0907 96.2032L38.0806 79.1832Z'
          fill='#FFC2AC'
        />
        <path
          d='M40.1011 113.223L23.0811 114.233L24.0912 131.253L41.1112 130.243L40.1011 113.223Z'
          fill='#FFC2AC'
        />
        <path
          d='M18.0308 29.1402L1.01074 30.1504L2.0209 47.1704L19.0409 46.1602L18.0308 29.1402Z'
          fill='#FFC2AC'
        />
        <path
          d='M20.0503 63.1773L3.03027 64.1875L4.04043 81.2075L21.0604 80.1973L20.0503 63.1773Z'
          fill='#FFC2AC'
        />
        <path
          d='M22.0708 97.2135L5.05078 98.2236L6.06094 115.244L23.0809 114.233L22.0708 97.2135Z'
          fill='#FFC2AC'
        />
        <path
          d='M24.0903 131.25L7.07031 132.26L8.08047 149.28L25.1005 148.27L24.0903 131.25Z'
          fill='#FFC2AC'
        />
      </g>
      <path
        d='M128.388 119.835C154.503 110.812 168.36 82.3269 159.337 56.2115C150.314 30.0962 121.829 16.2398 95.7138 25.2625C69.5984 34.2853 55.7421 62.7703 64.7648 88.8857C73.7875 115.001 102.273 128.857 128.388 119.835Z'
        fill='#FFFAF8'
        stroke='#FF4C00'
        strokeWidth='3.5'
        strokeMiterlimit='10'
      />
      <path
        d='M124.155 107.583C143.505 100.898 153.771 79.7929 147.086 60.4435C140.401 41.094 119.296 30.8276 99.9463 37.5127C80.5969 44.1978 70.3305 65.303 77.0156 84.6524C83.7007 104.002 104.806 114.268 124.155 107.583Z'
        fill='#FCE8E5'
      />
      <g clipPath='url(#clip0_947_76324)'>
        <path
          d='M47.1838 65.8787L49.383 61.0273C50.1245 59.3931 50.3792 57.4433 50.0997 55.5447L47.0586 35.9905C46.9854 35.5225 46.5883 35.1843 46.1347 35.2068C45.6444 35.2316 45.2672 35.6656 45.2911 36.1777L46.0402 51.3551C46.0672 51.919 45.6535 52.3963 45.1135 52.4241C44.5734 52.4496 44.1144 52.0154 44.0852 51.4517L43.3361 36.2742C43.31 35.7623 42.8923 35.3668 42.4018 35.3894L42.3565 35.3921L42.3111 35.3948C41.8208 35.4196 41.4436 35.8536 41.4675 36.3656L42.2166 51.5431C42.2436 52.1069 41.83 52.5843 41.29 52.612C40.7499 52.6375 40.2908 52.2033 40.2617 51.6396L39.5125 36.4622C39.4865 35.9502 39.0687 35.5547 38.5762 35.5796C38.1226 35.6022 37.7592 35.9769 37.7331 36.4508L36.6309 56.2076C36.5411 58.126 36.9861 60.0386 37.8843 61.5928L40.5523 66.2095C41.0376 67.0494 41.2976 68.0718 41.29 69.1121L41.325 119.237C41.2698 123.636 43.8168 125.558 46.7915 125.412C49.7186 125.268 52.2792 122.857 51.7445 118.703L46.7342 68.8518C46.6206 67.8143 46.7793 66.765 47.1815 65.8767L47.1838 65.8787Z'
          fill='#FF7C44'
        />
      </g>
      <path
        d='M175.2 69.1451C175.447 69.5804 175.381 69.7814 175.446 69.98L175.647 77.9546L175.324 116.602C175.236 120.545 177.603 122.278 180.388 122.162C183.125 122.046 185.542 119.897 185.075 116.172C185.075 116.172 181.418 75.25 181.048 69.0049C180.677 62.7597 179.294 42.2796 178.932 28.4041C178.909 27.4635 177.92 26.9759 177.246 27.5755C173.652 30.7718 170.315 36.1241 170.592 47.113C170.837 56.845 172.835 63.2893 175.2 69.1451Z'
        fill='#FF7C44'
      />
      <defs>
        <clipPath id='clip0_947_76324'>
          <rect
            width='13.5796'
            height='90.1632'
            fill='white'
            transform='translate(35.3594 35.7705) rotate(-3.39658)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
