import * as React from 'react'
import { RewardsTabs } from '../RewardsTabs'
import { RewardPoints } from '../RewardPoints'
import { useGuestAccount } from '../GuestAccountProvider'
import { LoyaltyAccountReward } from '../GuestAccountProvider/IGuestAccountData'

export interface RewardsProps {
  testId?: string | number
  onOpenCheckInLoyaltyModal?: () => void
}

export const Rewards = ({
  testId = 'rewards',
  onOpenCheckInLoyaltyModal
}: RewardsProps) => {
  const { guestAccountData } = useGuestAccount()

  const leftToTarget: number | undefined =
    guestAccountData?.data?.account?.rewards?.reduce(
      (acc, reward: LoyaltyAccountReward) => {
        if (reward?.leftToTarget && reward?.leftToTarget > acc) {
          return reward.leftToTarget
        }
        return acc
      },
      0
    )

  return (
    <div data-testid={testId}>
      <RewardPoints
        leftToTarget={leftToTarget}
        rewardsBalance={parseFloat(
          guestAccountData?.data?.account?.rewardsBalance || '0'
        )}
        isLoading={guestAccountData?.isLoading}
      />
      <RewardsTabs onOpenCheckInLoyaltyModal={onOpenCheckInLoyaltyModal} />
    </div>
  )
}
