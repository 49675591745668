import * as React from 'react'
import cx from 'classnames'
import NumberFormat from 'react-number-format'
import type {
  NumberFormatProps,
  NumberFormatPropsBase,
  NumberFormatValues
} from 'react-number-format'
import { useUniqueId } from '@toasttab/buffet-utils'
import { getInputBorderClassName, getInputClassName } from '../utils'
import {
  Border,
  HelperText,
  Label,
  LabeledHelperTextProps,
  Prefix,
  Suffix,
  getInputPaddingLeft,
  getInputPaddingRight
} from '@toasttab/buffet-pui-text-base'
import { SharedInputProps } from '../types'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

// export NumberFormatValues from `react-number-format` for use in other components
export { NumberFormatValues }

export interface NumberInputBaseProps
  extends SharedInputProps,
    TestIdentifiable,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'type' | 'size' | 'prefix' | 'value' | 'defaultValue' | 'onChange'
    >,
    Omit<NumberFormatPropsBase, 'size' | 'prefix' | 'suffix' | 'onChange'> {
  value?: number | string
  /**
   * onChange called with a value object
   * {
   *   formattedValue: '$23,234,235.56', //value after applying formatting
   *   value: '23234235.56', //non formatted value as numeric string 23234235.56, if you are setting this value to state make sure to pass isNumericString prop to true
   *   floatValue: 23234235.56 //floating point representation. For big numbers it can have exponential syntax
   * }
   */
  onChange?: NumberFormatProps['onValueChange']
  /** A suffix that is rendered inline with the text instead of at the end of the input. The same as 'suffix' from react-number-format. */
  inlineSuffix?: string
  /** Allow the input to be transparent. Will cause a Safari issue with placeholder text. See https://github.com/toasttab/buffet/pull/882. */
  transparentBackground?: boolean
  /** If defined it limits to given decimal scale. From react-number-format. */
  decimalScale?: NumberFormatProps['decimalScale']
  /** single character string or boolean true (true is default to ,). From react-number-format.	*/
  thousandSeparator?: NumberFormatProps['thousandSeparator']
  /** (values) => true or false; A checker function to check if input value is valid or not. From react-number-format. */
  isAllowed?: NumberFormatProps['isAllowed']
  /** Classes that will be directly applied to the Border component **/
  borderClassName?: string
  invalid?: boolean
  /** An optional class to affect the border radius (e.g. use rounded-r-none to remove the radius on the right hand side) */
  borderRadiusClassName?: string
  /**
   * Apply a className to the input element. For example, adding 'w-10' will set the size of the input to 40px, but will allow the label to stay full width.
   */
  inputContainerClassName?: string
  /**
   * Provides a hint about the type of data that might be entered by the user, this allows the browser to display an appropriate virtual keyboard.
   * Note that this is set to "decimal" by default, and on some devices that makes it impossible to enter a hyphen for negative numbers.
   * If you think negative numbers may need to be entered, you should probably switch this to "text".
   */
  inputMode?: React.InputHTMLAttributes<HTMLInputElement>['inputMode']
  label?: React.ReactNode
}

export const NumberInputBase = React.forwardRef<
  HTMLInputElement,
  NumberInputBaseProps
>(function NumberInput(
  {
    name,
    onChange,
    borderClassName,
    className,
    style,
    disabled,
    readOnly,
    prefix,
    prefixVariant = Prefix.Variant.bgGray,
    suffix,
    suffixVariant = Suffix.Variant.bgGray,
    size = 'auto',
    inputMode = 'decimal',
    inlineSuffix,
    decimalScale,
    thousandSeparator,
    testId,
    invalid,
    transparentBackground,
    changed = false,
    required,
    borderRadiusClassName,
    inputContainerClassName,
    label,
    ...restProps
  },
  ref
) {
  return (
    <Border
      disabled={disabled}
      readOnly={readOnly}
      invalid={invalid}
      prefix={prefix}
      prefixVariant={prefixVariant}
      suffix={suffix}
      suffixVariant={suffixVariant}
      testId={testId}
      className={cx(
        getInputBorderClassName({
          size,
          readOnly: readOnly && !disabled,
          transparentBackground,
          changed
        }),
        borderClassName
      )}
      borderRadiusClassName={cx(borderRadiusClassName, inputContainerClassName)}
      label={label}
    >
      <NumberFormat
        getInputRef={ref}
        name={name}
        data-testid={testId}
        suffix={inlineSuffix}
        style={{
          ...style,
          paddingLeft: getInputPaddingLeft({
            prefix,
            prefixVariant
          }),
          paddingRight: getInputPaddingRight({
            suffix,
            suffixVariant
          })
        }}
        className={getInputClassName({
          disabled,
          readOnly,
          prefix,
          prefixVariant,
          suffix,
          suffixVariant,
          className,
          transparentBackground,
          changed,
          roundedClassName: borderRadiusClassName
        })}
        disabled={disabled}
        readOnly={readOnly}
        onValueChange={onChange}
        decimalScale={decimalScale}
        prefix=''
        inputMode={inputMode}
        thousandSeparator={thousandSeparator}
        aria-invalid={invalid}
        aria-required={required}
        required={required}
        {...restProps}
      />
    </Border>
  )
})
NumberInputBase.displayName = 'NumberInputBase'

export interface NumberInputProps
  extends NumberInputBaseProps,
    LabeledHelperTextProps {}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(
    {
      name,
      id,
      label,
      helperIconButton,
      containerClassName,
      disabled,
      prefixVariant = Prefix.Variant.bgGray,
      suffixVariant = Suffix.Variant.bgGray,
      size = 'auto',
      testId,
      invalid,
      helperText,
      errorText,
      preserveHelpSpace,
      required,
      ...restProps
    },
    ref
  ) {
    const uniqueTestId = useUniqueId(testId, 'number-input-')
    const uniqueId = useUniqueId(id, 'number-input-')
    return (
      <div
        className={containerClassName}
        data-testid={`${uniqueTestId}-container`}
      >
        <Label
          name={uniqueId}
          disabled={disabled}
          helperIconButton={helperIconButton}
          required={required}
        >
          {label}
        </Label>
        <NumberInputBase
          ref={ref}
          size={size}
          name={name}
          id={uniqueId}
          disabled={disabled}
          invalid={invalid}
          prefixVariant={prefixVariant}
          suffixVariant={suffixVariant}
          testId={uniqueTestId}
          required={required}
          label={label}
          {...restProps}
        />
        <HelperText
          testId={`${uniqueTestId}-helper-text`}
          disabled={disabled}
          invalid={invalid}
          errorText={errorText}
          helperText={helperText}
          preserveHelpSpace={preserveHelpSpace}
        />
      </div>
    )
  }
)

NumberInput.displayName = 'NumberInput'
