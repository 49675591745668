import { AxiosResponse } from 'axios'

export const getRestaurantFeaturesRepository = async (
  params: any
): Promise<AxiosResponse<any, any>> => {
  const {
    meta: { instance },
    queryKey: [, restaurantGuid]
  } = params
  return instance.get('/guest/restaurant/features', {
    headers: {
      'Toast-Restaurant-External-ID': restaurantGuid
    }
  })
}
