import * as React from 'react'
import { SpilledNoodles } from '@local/assets'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Trans, t } from '@local/translations'

export interface ErrorStateProps {
  testId?: string | number
  callback?: () => void
}

/**
 * ErrorState component
 */
export const ErrorState = ({
  testId = 'error-state',
  callback
}: ErrorStateProps) => {
  return (
    <div data-testid={testId} className='bg-white p-6'>
      <div className='flex justify-center mb-4'>
        <SpilledNoodles className='w-80' />
      </div>
      <div className='mb-4'>
        <Trans
          i18nKey='loyalty-page-new-version.error-state.title'
          components={[
            <h1 className='text-center type-headline-5' />,
            <h1 className='text-center type-headline-5' />
          ]}
        />
        <p className='text-center text-secondary type-default mt-1'>
          {t('loyalty-page-new-version.error-state.subtitle')}
        </p>
      </div>
      <div className='flex justify-center'>
        <Button
          variant='secondary'
          onClick={callback}
          testId={testId + '--try-again-button'}
        >
          {t('loyalty-page-new-version.error-state.button')}
        </Button>
      </div>
    </div>
  )
}
