import { AU, CA, IN, IE, NZ, GB, US } from 'country-flag-icons/react/3x2'

export const flags = {
  AU,
  CA,
  IN,
  IE,
  NZ,
  GB,
  US
}

export type Flags = typeof flags
