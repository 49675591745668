import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  IGuestData,
  updateAccountDataRepository
} from '@local/common/src/network'
import { AxiosInstance } from 'axios'

export const useCompleteProfileService = () => {
  const queryClient = useQueryClient()

  const instance = queryClient.getDefaultOptions()?.mutations?.meta
    ?.instance as AxiosInstance

  return useMutation({
    mutationFn: (data: IGuestData) => {
      return updateAccountDataRepository(instance, data)
    }
  })
}
