import * as React from 'react'
import { TreatIcon } from '@local/assets/src/TreatIcon'
import { t } from '@local/translations'
import { Button } from '@toasttab/buffet-pui-buttons'

export interface BirthdayTreatCardProps {
  testId?: string | number
  hasBirthdayTreat: boolean
  onClickButton?: () => void
}

/**
 * BirthdayTreatCard component
 */
export const BirthdayTreatCard = ({
  testId,
  hasBirthdayTreat,
  onClickButton
}: BirthdayTreatCardProps) => {
  return (
    <div
      data-testid={testId}
      className='flex flex-row justify-between w-full border-2 rounded-[12px] bg-white'
    >
      <div className='py-3 pl-4'>
        <p className='flex-grow type-subhead font-semibold mb-3'>
          {hasBirthdayTreat
            ? t(
                'loyalty-page-new-version.rewards-tabs.birthday-treat-card.treat-available.text'
              )
            : t(
                'loyalty-page-new-version.rewards-tabs.birthday-treat-card.non-treat-available.text'
              )}
        </p>
        <Button onClick={onClickButton} size='sm'>
          {hasBirthdayTreat
            ? t(
                'loyalty-page-new-version.rewards-tabs.birthday-treat-card.treat-available.button'
              )
            : t(
                'loyalty-page-new-version.rewards-tabs.birthday-treat-card.non-treat-available.button'
              )}
        </Button>
      </div>
      <div className='flex-grow-0 flex flex-col justify-end'>
        <TreatIcon className='w-20 xs:w-28 mr-4' />
      </div>
    </div>
  )
}
