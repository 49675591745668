export const countryIsoCodes = [
  'AU',
  'CA',
  'IN',
  'IE',
  'NZ',
  'GB',
  'US'
] as const

export type CountryIsoCode = (typeof countryIsoCodes)[number]
