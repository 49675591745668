import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { getRestaurantFeaturesRepository } from '../repositories'

export const useRestaurantFeaturesService = (
  restaurantGuid?: string
): UseQueryResult<any, any> => {
  return useQuery({
    queryKey: ['restaurantFeaturesService', restaurantGuid],
    queryFn: getRestaurantFeaturesRepository,
    enabled: !!restaurantGuid
  })
}
