import React from 'react'
import {
  GuestAccountProvider,
  Home,
  RestaurantFeaturesProvider
} from '@local/loyalty-page-new-version'
import { initialiseApiIntance } from '@local/common/src/network'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export const App = () => {
  const instance = initialiseApiIntance()

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        meta: {
          instance
        }
      },
      mutations: {
        meta: {
          instance
        }
      }
    }
  })
  return (
    <QueryClientProvider client={client}>
      <GuestAccountProvider>
        <RestaurantFeaturesProvider>
          <Router basename='/loyalty/welcome'>
            <Routes>
              <Route path='/:secureToken' element={<Home />} />
            </Routes>
          </Router>
        </RestaurantFeaturesProvider>
      </GuestAccountProvider>
    </QueryClientProvider>
  )
}
