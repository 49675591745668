import * as React from 'react'
import {
  Alert,
  BirthdayTreatCard,
  CheckInLoyaltyModal,
  CompleteProfileModal,
  DownloadAppCard,
  Footer,
  GiftCard,
  Header,
  HowItWorks,
  LinkedCardsManagementModal,
  // LocationPickerModal, TODO: Location update
  Rewards,
  useGuestAccount,
  IGuestAccountData,
  useRestaurantFeatures,
  useHome,
  IRestaurantFeaturesData,
  ErrorState
} from '@local/loyalty-page-new-version'
import {
  useGuestAccountService,
  useRestaurantFeaturesService
} from '@local/common/src/network'
import { useParams } from 'react-router-dom'
import { useSentry } from 'banquet-runtime-modules'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
// import locations from './mock/locations.json' TODO: Location update

export interface HomeProps {
  testId?: string | number
}

export const Home = ({ testId }: HomeProps) => {
  const sentry = useSentry()
  const { secureToken } = useParams()

  const { setGuestAccountData, guestAccountData } = useGuestAccount()

  const {
    isError: isErrorGuestAccountData,
    isLoading: isLoadingGuestAccountData,
    ...guestAccountQuery
  } = useGuestAccountService(secureToken)

  React.useEffect(() => {
    setGuestAccountData((previous: IGuestAccountData) => ({
      ...previous,
      isLoading: isLoadingGuestAccountData
    }))
  }, [isLoadingGuestAccountData])

  React.useEffect(() => {
    setGuestAccountData((previous: IGuestAccountData) => ({
      ...previous,
      isError: isErrorGuestAccountData,
      error: guestAccountQuery.error
    }))

    if (isErrorGuestAccountData) {
      sentry.captureException(guestAccountQuery.error)
    }
  }, [isErrorGuestAccountData])

  React.useEffect(() => {
    if (guestAccountQuery.isSuccess) {
      setGuestAccountData((previous: IGuestAccountData) => ({
        ...previous,
        data: guestAccountQuery?.data?.data
      }))
    }
  }, [guestAccountQuery?.data])

  const { setRestaurantFeaturesData, restaurantFeaturesData } =
    useRestaurantFeatures()

  const restaurantFeaturesQuery = useRestaurantFeaturesService(
    guestAccountData?.data?.restaurantGuid
  )

  React.useEffect(() => {
    setRestaurantFeaturesData((previous: IRestaurantFeaturesData) => ({
      ...previous,
      isLoading: restaurantFeaturesQuery.isLoading
    }))
  }, [restaurantFeaturesQuery.isLoading])

  React.useEffect(() => {
    setRestaurantFeaturesData((previous: IRestaurantFeaturesData) => ({
      ...previous,
      isError: restaurantFeaturesQuery.isError,
      error: restaurantFeaturesQuery.error
    }))

    if (restaurantFeaturesQuery.isError) {
      sentry.captureException(restaurantFeaturesQuery.error)
    }
  }, [restaurantFeaturesQuery.isError])

  React.useEffect(() => {
    if (restaurantFeaturesQuery.isSuccess) {
      setRestaurantFeaturesData((previous: IRestaurantFeaturesData) => ({
        ...previous,
        data: restaurantFeaturesQuery?.data?.data
      }))
    }
  }, [restaurantFeaturesQuery?.data])

  const {
    isCompleteProfileModalOpen,
    isCheckInLoyaltyOpen,
    isCardManagementModalOpen,
    hasCompletedProfile,
    hasBirthdayTreatAvailable,
    onBirthdayTreatClick,
    onCheckInLoyaltyClick,
    onCompleteProfileModalClose,
    onCheckInLoyaltyModalClose,
    onCardManagementModalClick,
    onCardManagementModalClose,
    openTakeOut,
    onAddBalanceClick
  } = useHome(guestAccountData?.data, restaurantFeaturesData?.data)

  //TODO: Location update
  // const [isSeeOtherLocationsOpen, setIsSeeOtherLocationsOpen] =
  //   React.useState<boolean>(false)

  // TODO: Location update
  // const onSeeOtherLocationsClick = () => {
  //   setIsSeeOtherLocationsOpen(true)
  // }

  // TODO: Location update
  // const onSeeOtherLocationsClose = () => {
  //   setIsSeeOtherLocationsOpen(false)
  //   return undefined
  // }

  const onReloadPage = () => {
    window.location.reload()
  }

  return (
    <>
      {isLoadingGuestAccountData && !isErrorGuestAccountData && (
        <MerryGoRound className='pin-center' />
      )}
      {isErrorGuestAccountData && !isLoadingGuestAccountData && (
        <div
          className='flex flex-col justify-center items-center bg-white w-full h-full absolute'
          data-testid={testId + '-error-state'}
        >
          <ErrorState callback={onReloadPage} />
          <div className='my-6 px-10 md:max-w-md'>
            <Footer />
          </div>
        </div>
      )}
      {!isErrorGuestAccountData && !isLoadingGuestAccountData && (
        <div
          className='flex flex-col justify-center items-center'
          data-testid={testId}
        >
          <Header
            programName={guestAccountData?.data?.messages?.programName || ''}
            programDescription={
              guestAccountData?.data?.messages?.programDescription || ''
            }
            logoUrl={
              guestAccountData?.data?.restaurantBranding?.thumbnailUrl || ''
            }
            backgroundUrl={
              guestAccountData?.data?.restaurantBranding?.backgroundUrl || ''
            }
            isLoading={isLoadingGuestAccountData}
          />
          {!hasCompletedProfile && !isLoadingGuestAccountData && (
            <div className='max-w-md mt-6'>
              <BirthdayTreatCard
                hasBirthdayTreat={hasBirthdayTreatAvailable}
                onClickButton={onBirthdayTreatClick}
              />
            </div>
          )}
          <div className='bg-white p-4 md:max-w-md mt-6 w-full rounded-t-lg'>
            <Rewards onOpenCheckInLoyaltyModal={onCheckInLoyaltyClick} />
          </div>
          <div className='bg-white md:max-w-md mt-2 w-full'>
            <DownloadAppCard
              onDownloadClick={openTakeOut}
              restaurantName='Johnny pizza'
            />
          </div>
          <div className='md:max-w-md mt-2 w-full'>
            <HowItWorks
              entryMode='phone'
              locations={guestAccountData?.data?.loyaltyGroup || []}
              isDigitalChannelSupported={true}
              seeQRCodeCallback={onCheckInLoyaltyClick}
              manageLinkedCreditCardsCallback={onCardManagementModalClick}
              orderOnlineCallback={openTakeOut}
              orderOnAppCallback={openTakeOut}
            />
          </div>
          <div className='bg-white md:max-w-md mt-2 w-full px-4 py-2 rounded-b-lg'>
            <GiftCard
              logoUrl=''
              balance={guestAccountData?.data?.account?.giftCardBalance || 0}
              restaurantDescription=''
              restaurantName={
                guestAccountData?.data?.messages?.programName || ''
              }
              addBalanceCallback={() =>
                secureToken && onAddBalanceClick(secureToken)
              }
            />
          </div>
          <div className='my-6 px-10 md:max-w-md'>
            <Footer />
          </div>
        </div>
      )}
      {isCheckInLoyaltyOpen && (
        <CheckInLoyaltyModal
          isOpen={isCheckInLoyaltyOpen}
          onCloseRequest={onCheckInLoyaltyModalClose}
          membershipNumber={guestAccountData?.data?.account?.number || ''}
        />
      )}
      {isCardManagementModalOpen && (
        <LinkedCardsManagementModal
          cardData={guestAccountData?.data?.account?.ccLinks || []}
          isOpen={isCardManagementModalOpen}
          onCloseRequest={onCardManagementModalClose}
          isLoading={guestAccountData?.isLoading || false}
        />
      )}
      {/* {isSeeOtherLocationsOpen && (
        <LocationPickerModal
          locations={locations}
          isModalOpen={isSeeOtherLocationsOpen}
          onModalClose={onSeeOtherLocationsClose}
        />
      )} */}
      {/* TODO: Location update
      Commented content until location selector modal be ready to go. */}
      {isCompleteProfileModalOpen && (
        <CompleteProfileModal
          onCloseRequest={onCompleteProfileModalClose}
          isBirthdayTreatAvailable={hasBirthdayTreatAvailable}
          rxName={guestAccountData?.data?.messages?.programName || ''}
          isOpen={isCompleteProfileModalOpen}
        />
      )}
      <Alert />
    </>
  )
}
