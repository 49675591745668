import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const TagIcon = React.forwardRef(function TagIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M9.407 20.477l-5.889-5.889a2.104 2.104 0 01.19-2.958l7.92-7.92A2.352 2.352 0 0113.371 3l5.637.253a1.777 1.777 0 011.735 1.734l.251 5.637a2.352 2.352 0 01-.71 1.743l-7.92 7.92a2.104 2.104 0 01-2.957.19z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
            />
            <path
              d='M16.227 9.245a1.477 1.477 0 100-2.953 1.477 1.477 0 000 2.953z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
