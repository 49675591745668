import * as React from 'react'
import { t } from '@local/translations'
import { Button } from '@toasttab/buffet-pui-buttons'

export interface DownloadAppCardProps {
  testId?: string | number
  restaurantName: string
  isToastTakeout?: boolean
  onDownloadClick?: () => void
}

/**
 * DownloadAppCard component
 */
export const DownloadAppCard = ({
  testId,
  restaurantName,
  isToastTakeout,
  onDownloadClick
}: DownloadAppCardProps) => {
  return (
    <div
      data-testid={testId}
      className='flex flex-row justify-between items-center p-3 px-4'
    >
      <div>
        <p className='type-subhead'>
          {isToastTakeout
            ? t(
                'loyalty-page-new-version.download-app-card.toast-takeout.text',
                {
                  rxName: restaurantName
                }
              )
            : t('loyalty-page-new-version.download-app-card.branded-app.text', {
                rxName: restaurantName
              })}
        </p>
      </div>
      <div>
        <Button size='sm' onClick={onDownloadClick}>
          {t('loyalty-page-new-version.download-app-card.button')}
        </Button>
      </div>
    </div>
  )
}
