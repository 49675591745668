import * as React from 'react'
import cx from 'classnames'
import {
  LockLockedIcon,
  CakeIcon,
  DiamondIcon
} from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { t } from '@local/translations'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export interface RewardTabRewardItemProps {
  testId?: string | number
  rewardPrize?: string
  rewardRequirement?: string
  isLocked?: boolean
  isBirthdayReward?: boolean
  redeemCallback?: () => void
  isLoading?: boolean
}

/**
 * RewardTabRewardItem component
 */
export const RewardTabRewardItem = ({
  testId = 'reward-tab-reward-item',
  rewardPrize,
  rewardRequirement,
  isLocked,
  isBirthdayReward,
  redeemCallback,
  isLoading
}: RewardTabRewardItemProps) => {
  return isLoading ? (
    <div
      data-testid={testId + '-loading-state'}
      className='border text-secondary rounded-[4px] px-4 py-2'
    >
      <div className='flex flex-row justify-start mt-2 mb-1'>
        <div>
          <Skeleton className='w-6 h-6' />
        </div>
        <div className='ml-2'>
          <Skeleton className='w-32 h-4 mb-1' />
          <Skeleton className='w-2/4 h-4' />
        </div>
      </div>
    </div>
  ) : (
    <div
      data-testid={testId}
      className={cx('border rounded-[4px] p-3 border-[#BAB7B5]', {
        'border-opacity-40 pt-3 pb-4': isLocked
      })}
    >
      <div className='flex flex-row justify-items-center items-center'>
        <div>
          {isLocked && (
            <LockLockedIcon
              className='text-disabled'
              testId={testId + '-locked-icon'}
            />
          )}
          {!isLocked && !isBirthdayReward && (
            <DiamondIcon
              className='text-default'
              testId={testId + '-diamond-icon'}
            />
          )}
          {!isLocked && isBirthdayReward && (
            <CakeIcon className='text-default' testId={testId + '-cake-icon'} />
          )}
        </div>
        <div className='ml-3'>
          <div
            className={cx(
              'type-subhead font-semibold',
              { 'text-disabled': isLocked },
              { 'text-default': !isLocked }
            )}
          >
            {rewardPrize}
          </div>
          <div
            className={cx('type-caption', {
              'text-disabled': isLocked,
              'text-secondary': !isLocked
            })}
          >
            {rewardRequirement}
          </div>
        </div>
        {!isLocked && ( //this last item should be align at the end of the row
          <div className='ml-auto'>
            <Button
              size='sm'
              variant='secondary'
              testId={testId + '-rendeem-button'}
              onClick={redeemCallback}
            >
              {t(
                'loyalty-page-new-version.header.reward-tab-reward-item.redeem'
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
