import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const LaunchIcon = React.forwardRef(function LaunchIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M11.667 5.757h-5.5c-.917 0-1.667.754-1.667 1.676v10.391c0 .922.75 1.676 1.667 1.676H16.5c.917 0 1.667-.754 1.667-1.676v-5.615m-3-7.709H19.5m0 0v4.274m0-4.274l-7 6.955'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
